
import React, { useState, useEffect } from 'react';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { cls as clsn } from 'views/NUI/utils'
import { Link } from "react-router-dom";
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import TrophySVGIcon from '../../styles/public/icons/TrophySVGIcon';

const mainMenu = [
	{
		name: 'About Us',
		icon: 'heart',
		cls: "flex",
		key: "about",
		link: "#"
	},
	{
		name: 'Promotions',
		icon: <TrophySVGIcon width={17} height={17} fill={"#696C70"} />,
		cls: "flex",
		key: "promotions",
		link: "#"
	},
	{
		name: 'Help',
		icon: 'help-msg',
		cls: "flex",
		key: "help",
		link: "#"
	},
	{
		name: 'Work With Us',
		icon: 'steth-heart',
		cls: "flex",
		key: "careers",
		link: "#"
	}
]

// Side Section
const intro = {
	about: {
		title: 'About Us',
		desc: `Founded in Melbourne in 2018, InstantScripts has helped hundreds of thousands of Australians access health and medical 
                services from the comfort of their homes.`
	},
	promotions: {
		title: 'Promotions',
		desc: `Do you need to call in sick or take a day off for carer's leave? Our online medical
				certificate service is quick, easy and convenient. Medical certificates are certified by
				Australian-registered doctors. Choose from four types of certificates.`
	},
	help: {
		title: 'Help',
		desc: ``
	},
	careers: {
		title: 'Careers',
		desc: `Work with us!`
	},
}

// Main Section
const tiles = {
	about: [
		{ title: 'Our story', desc: 'Find out more about Australia\'s leading online medical clinic.', link: '/about-us' },
		{ title: 'News', desc: 'Get the latest health and medical news in Australia.', link: 'https://www.instantscripts.com.au/blog' },
		{ title: 'Privacy', desc: 'Read our privacy policy and understand our security protocol.', link: '/privacy' },
		{ title: 'For Pharmacies', desc: 'See how our service can help your customers access prescriptions.', link: '/for-pharmacies' },
		{ title: 'In the media', desc: 'See the latest media releases from InstantScripts.', link: 'https://www.instantscripts.com.au/media_release/' },
		{ title: 'InstantCosmetics', desc: 'Supporting cosmetic nurses with access to quality cosmetic doctors.', link: 'https://www.instantcosmetics.com.au/' },
	],
	help: [
		{ title: 'FAQ', desc: 'Get answers to our patients\' most frequently asked questions.', link: '/faq' },
		{ title: 'Help centre', desc: 'Access help and support on our service from our local team.', link: 'https://help.instantscripts.com.au/en/' },
		{ title: 'Contact us', desc: 'Contact our friendly team who are always more than happy to help.', link: '/contact-us' },
	],
	promotions: [
		{ title: 'OnePass', desc: 'See how OnePass members enjoy exclusive benefits.', link: '/onepass' },
		{ title: 'Refer a friend', desc: 'Introduce your friends to InstantScripts and get rewarded.* Terms and conditions apply.', link: '/refer-a-friend' },
	],
	careers: [
		!app.settings.is_ionic && { title: 'Careers', desc: 'Are you a doctor who would like to work with us? Find out more.', link: 'https://careers.smartrecruiters.com/InstantScripts' },
	],
}

const BottomMenu = ({ inSideMenu, cls = 'main', setMainSideMenu, onMenuChange }) => {
	const [current, setCurrent] = useState();
	const [referenceElement, setReferenceElement] = useState()
	const [sideMegaMenu, setSideMegaMenu] = useState(true)
	const [popperElement, setPopperElement] = useState()
	const { styles, attributes } = usePopper(referenceElement, popperElement)
	const [panel, setPanel] = useState()

	const handleShowMenu = (val) => {
		setSideMegaMenu(val)
		setMainSideMenu?.(val)
	}

	const handleOpen = (open) => {
		useEffect(() => {
			!open && handleShowMenu(true)
		}, [open])
	}

	useEffect(() => {
		setPanel(
			<Popover.Panel
				ref={popperElement}
				className={clsn(inSideMenu ? "side-menu h-full overflow-y-auto pb-24 mt-[62px] lg:mt-0 lg:px-[64px]"
					: "lg:px-9 mt-6 bg-is-gray-20", "panel bg-white w-full shadow-lg z-50")}
				style={styles.popper}
				{...attributes.popper}
			>
				<div className={clsn(inSideMenu ? "lg:pt-10" : "md:grid-cols-3 p-4 sm:px-8 md:py-10 lg:p-10 gap-4", "relative grid w-full grid-cols-1 mx-auto max-w-screen-menu-xl")}>
					{/* side section */}
					<section className={clsn(inSideMenu && "px-4 py-6", "section-side col-span-1 h-full")}>
						{intro[current] &&
							<>
								<h3 className="text-is-blue font-bold">{intro[current].title}</h3>
								<p className={clsn(!inSideMenu && "xl:w-[90%]")}>{intro[current].desc}</p>
							</>
						}
					</section>

					{/* main section */}
					<section className="section-main col-span-2 h-full">
						{tiles[current] &&
							<div className={clsn(!inSideMenu ? "sm:grid-cols-2 lg:grid-cols-3 pb-[80px] md:pb-0 gap-1" : "px-2", "tiles grid grid-cols-1")}>
								{tiles[current].map((el, i) => {
									if (!el) return
									const content = <><span className="flex justify-between items-stretch mb-2">
										<span>
											<p className="font-bold mt-0">{el.title}</p></span>
										<span className="flex items-start">
											{el.new && <span className="bg-is-blue text-white text-sm p-2 font-bold rounded-[1.25rem] max-h-5 mx-1 mt-1 flex items-center">New</span>}
											{el.ext ? <SIcon size="xs" name="external" cls="text-is-black hover:text-is-blue" bold /> :
												<SIcon size="xs" name="chevron" svg_cls="-rotate-90" cls="text-is-black hover:text-is-blue" bold />}</span>
									</span>
										<p className="w-[90%] desc text-sm">{el.desc}</p>
									</>

									return <Popover.Button
										as={el.link?.startsWith('https://') ? 'a' : Link} href={el.link}
										key={i} to={el.link}
										className={clsn(inSideMenu && "border-t border-is-50 rounded-none", "hover:border-transparent tile col-span-1 text-is-black h-full p-4 cursor-pointer rounded-lg hover:bg-is-blue-20 transition duration-200 ease-in min-h-[104px]")}
										onClick={() => { inSideMenu && onMenuChange(false); }}
									>
										{content}
									</Popover.Button>
								})}
							</div>
						}
					</section>
				</div>
			</Popover.Panel>
		)

	}, [current])

	return (

		<Popover.Group className={`flex justify-end w-full mx-auto mega-menu bg-is2-blue-50 !px-5 ${inSideMenu ? "flex-col pb-24" : "flex-row items-center mr-2"}`}>

			<h3 className="bg-is2-blue-50 pt-10 px-6 pb-4 !mb-0 text-lg font-bold text-is2-gray-700 border-b border-b-white">About InstantScripts</h3>

			{mainMenu.length > 0 && mainMenu?.filter(Boolean).map((el, i) => {
				
				return <Popover key={i}>
					{({ open, close }) => (
					

						<>
							{/* Main Menu */}
							{inSideMenu && handleOpen(open)}

							{inSideMenu && !sideMegaMenu &&
								<span className="text-is-white hover:text-is-white">
									<button onClick={(e) => {
										handleShowMenu(true)
										close(referenceElement)
									}}
										className="btn back flex items-center w-2/3 color-white text-white bg-is2-blue lg:bg-white lg:text-is2-gray-body absolute top-[3px] left-[8px] py-4 cursor-pointer z-50"
									>
										<SIcon name="chevron" size="xxs" svg_cls='rotate-90 mr-1' bold />
										<span className="pt-[0.15rem]">Back</span>
									</button>
								</span>
							}

							<div className="relative z-10">
								<div className={clsn(inSideMenu ? "px-0" : "xl:px-1.5 2xl:p-2")}>
									<Popover.Button
										ref={setReferenceElement}
										key={i}
										onClick={() => {
											setCurrent(el.key)
											inSideMenu && handleShowMenu(false)
										}}
										className={clsn(cls, el.cls,
											inSideMenu && !sideMegaMenu && "hidden",
											!inSideMenu && "items-center",
											inSideMenu ? "side-btn w-full p-4 bg-is2-blue-50 border-b border-b-white" : "hover:text-is2-blue-50 ml-4 rounded-md text-sm",
											open ? "open text-is-blue" : "text-is-black",
											"popover-btn font-semibold button group flex inline-flex items-end")}>
										{typeof el.icon === 'string' && <SIcon name={el.icon} size="xs" cls="w-6 h-6 mr-2" />}
										{typeof el.icon !== 'string' && <div className="p-1 w-6 h-6 mr-2 sm:self-start inline-block">{el.icon}</div>}
										<span className="max-w-[80%] text-left sm:max-w-none sm:text-center">{el.name}</span>
										{inSideMenu && <SIcon name="chevron" size="xs" svg_cls='mr-1' cls="absolute right-4 text-is-black group-hover:text-is-blue cursor-pointer" bold />}
									</Popover.Button>
								</div>
							</div>

							{/* Dropdown Menu */}
							{panel}

						</>
					)}
				</Popover>
			})}
		</Popover.Group>

	)
}

export default BottomMenu
