import React, { Component, createRef, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Container, Input, Modal } from 'semantic-ui-react'
import getNestedObject from '../helpers/getNestedObject'

import Breadcrumb from '../MenuNav/Breadcrumb'
import KeyName from '../MenuNav/KeyName'

import cat_model from 'models/cat_model'
import user_model from 'models/user_model'
import med_model from "models/med_model"

import gtm from '../providers/gtm'
import logger from 'xAppLib/libs/logger'

import debounce from 'xAppLib/libs/debounce'
import { cls } from 'views/NUI/utils'
import Alert from '../../views/NUI/Alert'
import { ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import LandingPage from '../../views/landing_pages/LandingPage'
import ConditionsAndServicesTiles from '../../views/med/ConditionsAndServicesTiles'
import { useParams } from 'react-router-dom/dist'
import CatCard from './CatCard';
import { excludedCats } from './constants';
import LoginBanner from "../UIelems/LoginBanner";

const defaultCatDesc = `Do you need a script for medication you are already on but can’t get to the doctor?\nOur doctors can help you access prescriptions, if you are eligible, for a wide range of health conditions including contraception, blood pressure, cholesterol, asthma, diabetes, hay fever, heartburn and reflux, migraine, skincare, gout, depression and anxiety.\nYou can fill out an online consultation form. This will be reviewed by one of our Australian-registered doctors and, if approved, they will send you a prescription, or you can have the medication delivered to you.\nIf your medication is not listed, or you need to speak a doctor about the right treatment, you can request a Telehealth Consultation.`

const onlinePrescriptionTile = { k: "online", d: "For when your script has run out. Limited range of medications only.", n: "Online Prescription", t: "r" };

export class Cat extends Component {
	cleanup = [];

	constructor (props) {
		super(props)
		
		// Cat.access(props)

		const cat_nm = props.alt_cat?.replace(/^_/, '') || props.match.params.cat_nm || 'pharm';
		cat_model.set_cat( cat_nm )

		this.state = {
			data_loaded: false,
			srch_str: new URLSearchParams(window.location.search).get("search"),
			cat : null,
			cat_nm,
			med_dets: [],
			placeholder: 'Search for services or conditions',
		}

		this.searchRef = createRef()
		app.on(app.events.SEARCH, () => {
			this.searchRef?.current?.focus()
			// this.setState({ placeholder: 'Search for medication...' }, this.searchRef?.current?.focus())
		})

		this.debouncedTrackResults = debounce(this.trackResults,500)
	}
	
	static access(props) {
		const cat_nm = props.alt_cat?.replace(/^_/, '') || props.match.params.cat_nm || 'pharm';
		// console.log('access', cat_nm, props.alt_cat, props.alt_cat?.replace(/^_/, ''), props.match.params.cat_nm);
		return user_model.limit_access('pg_cat', cat_nm, true, app.history.location.pathname );
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	componentDidMount () {
		this.load_recs()
	}

	componentWillUnmount () {
		this.cleanup.forEach(fn => fn());
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.match.params.cat_route != this.props.match.params.cat_route) {
			if (this.state.srch_str != '') {
				this.setState({	srch_str: null	}, _=>this.filter_data() )
			} else 
				this.filter_data()
		}
		
		if (prevState.srch_str != this.state.srch_str) {
			this.filter_data(true)
		}
		if (prevState.cat_data_db != this.state.cat_data_db) {
			this.filter_data()
		}
	}
	
	filter_data(scrh = false) {
		const { cat_nm, cat_data_db, flat_cat_list } = this.state
		const srch_str = this.state.srch_str?.trim()
		const cat_loc = this.props.match.params.cat_route || ''

		const curr_cat_path = cat_loc.replace(/\//g, '/i/')
		const cat_path = cat_loc.replace(/\//g, '/i/')+(cat_loc?'/i':'')
		const cat_data = srch_str && srch_str=='' && flat_cat_list 
						|| srch_str && 
							Object.keys(flat_cat_list)
								.filter( key => {
									return flat_cat_list[key]?.t !== 's' && flat_cat_list[key]?.t !== 'c' && excludedCats.includes(flat_cat_list[key].par_id) && (
										flat_cat_list[key]?.n.toLowerCase().includes(srch_str.toLowerCase())
										|| app.app_data.ws_data.cat_data?.[cat_nm].meds_sub_list?.[key]?.alt_name?.toLowerCase().includes(srch_str.toLowerCase())
										|| flat_cat_list[key]?.f?.alt?.join("").toLowerCase().includes(srch_str.toLowerCase())
										|| cat_data_db[flat_cat_list[key]?.par_id]?.f?.alt?.join("").toLowerCase().includes(srch_str.toLowerCase())
									);
								} )
								.reduce( (obj, key) => ({
														...obj,
														[key]: flat_cat_list[key]
													})
													, {} )
						|| Object.assign({}, ...[... new Set([cat_path, cat_nm !== 'cosm' ? '' : null])].map(c => c!== null && getNestedObject(cat_data_db, c)))
						|| []

		const cats_show_data =
						cat_data && Object.keys(cat_data)
							.filter( k => user_model.check_access('show_medcat', {k, cat_nm}) && !['mens', 'womens'].includes(k) )
							// .filter( k => cat_data[k].active!=false )
							// .sort( (a, b) => cat_data[a].n.localeCompare(cat_data[b].n) )
							.sort( (a, b) => cat_model.sort_cat_view(cat_data[a], cat_data[b], srch_str) )
							.map( k => ({k,...cat_data[k]}) )

		if(scrh){
			let srch_show_data = [...(this.state.cats_show_data??[]).filter( ({ n, k, t, f }) => n && !excludedCats.includes(k) && t !== 'm' && !["weight loss", "covid-19"].includes(n?.toLowerCase()) && ( n?.toLowerCase().includes(srch_str?.toLowerCase()) || f?.alt?.join("").toLowerCase().includes(srch_str?.toLowerCase()) || "script".includes(srch_str?.toLowerCase()) ) ).map(u => ({...u, n: `${u.n} Online Prescription`, t: 'r'})), ...cats_show_data];
			if(cat_nm !== 'cosm'){
				srch_show_data.find(({ par_id, t }) => par_id === 'subscr' || t === 'r' ) && srch_show_data.push(onlinePrescriptionTile);
				(srch_show_data.length === 0) && srch_show_data.push(...[onlinePrescriptionTile, {...this.state.flat_cat_list['DocConsCov'], k: 'DocConsCov'}]);
			}

			this.setState({srch_show_data})
		} else {
			this.setState({cats_show_data})
		}

		const log_data = {app_nm:app.runtime.app, cat_nm, route_id:srch_str ? 'search' : cat_loc, route_name: srch_str || cat_loc=='' && 'top' || getNestedObject(cat_data_db, curr_cat_path)?.n}
		// console.log('Cat :: cat_view', log_data )

		const meds = cats_show_data.filter(m=>m.t != 'x').map(this.to_gtm_product).filter(Boolean)
		if(cat_loc == 'DocCons' || cat_nm == 'patho') meds.map(m => this.med_data(m.id))
		this.debouncedTrackResults(srch_str,meds,log_data)	
	
	}

	trackResults = (srch_str,meds,log_data) => {
		if (srch_str) {
			gtm.cat_search(srch_str,meds)	
		}
		if (meds.length) {
			gtm.impressions(meds)	
			logger.usg_log('cat_view', log_data.cat_nm+'::/'+log_data.route_id, log_data.route_name, log_data)
		}
		
	}


	to_gtm_product = (m,i) => {
		const srch_str = this.state.srch_str?.trim()
		const cat_crumb = this.gtm_cat(m)

		return {
			id:m.k,
			name:(cat_crumb != '' ? cat_crumb + ' : ' : '') + m.n,
			category: srch_str || cat_crumb,
			list: this.gtm_list(m),
			dimension4: this.state.cat_nm,
			position: i+1,
		}
	}

	gtm_list(m) {
		const srch_str = this.state.srch_str?.trim()
		const cat_crumb = this.gtm_cat(m)
		return srch_str ? `srch: ${srch_str}` : cat_crumb
	}

	gtm_cat(m) {
		const cat_loc = this.props.match.params.cat_route || ''
		const { cat_avail_list } = this.props.data
		const srch_str = this.state.srch_str?.trim()

		return (srch_str&&m.l||cat_loc).split('/').map(c=>cat_avail_list[c]?.n).filter(Boolean).join('/')

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	load_recs () {

			this.cleanup.push(cat_model.watch_all_records( (d)=>this.upd_res(d) ));

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------
	
	async med_data (id) {
		const med_data = await med_model.get_record(id)
		this.setState({med_dets: [...this.state.med_dets, med_data]})
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------


	async upd_res (r) {

		if (r.DocCons?.i.DocConsCov) {
			
			r.DocCons.i.DocConsCov.d = <React.Fragment>
										Our General Practitioners have broad knowledge and the skills to treat a wide range of health issues. Where appropriate, they will provide treatment, referrals to an appropriate medical specialist or other health professionals. 
										<br/>
										Our doctors providing Telehealth consultations will not prescribe medications for controlled medications such as Diazepam, Endone, Lyrica and other addictive medications. Please do not request a consultation if you require these medications.
										<br/>
										{/*Bulk billed for eligible users with a valid medicare card.*/}
									</React.Fragment>;

			r.DocCons.i.DocConsWL.d = <React.Fragment>
										Our weight loss specialists are general practitioners who have specialist knowledge to guide you with your weight loss needs. 
										<br/>
										Through an extended Telehealth consultation our medical practitioners will provide information and build a treatment plan to help you reach your weight loss goals.
										<br/>
										The cost is $150 for 30 mins consultation.
									</React.Fragment>
		}

		if (app.settings.is_ionic) {
			try {
				// So there's a weird bug on ionic where the cat list sometimes 
				// doesn't render correctly. It seems to be related to 
				// the #page-wrap > section element having overflow styles applied 
				// to it. The quick fix is to simply quickly toggle the overflow style
				// off then back on
				const el = Array.from(document.querySelectorAll('#page-wrap > main')).pop()
				el.style.overflowX = el.style.overflowY = 'visible'
				setTimeout(_=>{
					el.style.overflowX = 'hidden'
					el.style.overflowY = 'scroll'
				},10)

			} catch(e) {
			}
		}

		
		const { cat_show_list, cat_tree_list } = await cat_model.get_cat_list({cat_nm:this.state.cat_nm}, this.state.data_loaded)
		// console.log('this.flat_cat_list', this.flat_cat_list);

		
		// this.filter_data()

		this.setState({
			data_loaded: true,
			cat_data_db: cat_tree_list,
			flat_cat_list: cat_show_list
			// cat_data_db: r,
			// flat_cat_list: this.flat_cat_list,
		})

		// Object.keys(cat_show_list)
		// 		.filter(key => cat_show_list[key].t !== 'm')
		// 		.reduce((obj, key) => {
		// 			obj[key] = cat_show_list[key];
		// 			return obj;
		// 		}, {})
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	cats_list = (srch = false) => {

		const { cats_show_data, srch_show_data, cat_nm } = this.state
		const srch_str = this.state.srch_str?.trim()

		if (!cats_show_data)
			return null

		const cat_loc = this.props.match.params.cat_route || ''
		const cats_data = srch ? srch_show_data || [] : cats_show_data
		const cat_code = cat_loc.split('/').pop()

		let cats_data_secs = [[]];

		cats_data.map(el => el.t == 's' ? cats_data_secs.push([el]) : cats_data_secs[cats_data_secs.length - 1].push(el));
		cats_data_secs = cats_data_secs.filter(el => el.length)

		if (srch || !cat_loc || excludedCats.includes(cat_loc) || cat_nm !== 'pharm') {
			return (
				<>
					{!(cats_data_secs?.length) && (
						<div role="alert" className='pt-4 pb-2 text-center'>
							<p>No results found</p>
							<p>Please try a different search or fewer characters.</p>
						</div>
					)}
					{
						
						<>
							{cats_data_secs?.map((sec, seci) => 
							<React.Fragment key={'catsecs'+seci+sec[0].n}>
								{sec[0].t == 's' && <h2 className={cls("mt-0 text-is-black text-lg md:text-xl", cat_loc === 'certs' ? 'text-center mb-6': 'text-left mb-0')}>{sec[0].n || 'Not sure what you need?'}</h2>}

								<div className={cls(app.settings.icosm ? "gap-4" : "gap-8", "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pt-6 pb-12")}>
									{
										sec.map((el, i) => {
											const el_conf = p => el.f?.[p] ?? (sec[0].t == 's' && sec[0].f?.[p]);

											return el.t != 's' && (cat_nm === 'cosm' || el.t !== 'c') && el_conf('hidden') !== true &&
											<CatCard
												key={i}
												el={el}
												i={i}
												el_conf={el_conf}
												cat_nm={cat_nm}
												srch={srch}
												srch_str={srch_str}
												cat_loc={cat_loc}
												cat_data_db={this.state.cat_data_db}
												onProductClick={() => gtm.productClick([this.to_gtm_product(el, i)], this.gtm_list(el))}
											/>
										}
										)
									}
								</div>
							</React.Fragment>)}
						</>
					}
				</>
            )
		}
		else {
			const catDescription = this.state?.flat_cat_list?.[cat_code]?.x
			const mappedDescription = catDescription?.split("\n") || defaultCatDesc?.split("\n")
			return (
				<div className='mb-4'>
					{mappedDescription.map((dp, dpi) => <p key={dpi}>{dp}</p>)}
					{catDescription &&
						<>
							<p>
								If you require a prescription for a medication you are already on, you can search for it in the search box above.
								You can then fill out a digital medical consultation form which will be reviewed by one of our Australian-registered doctors.
								If approved, our doctors will issue you a prescription which you can take to any pharmacy or have the medication delivered to your home.
								If your medication is not listed, or you need to speak to a doctor about your condition, you can request a Telehealth Consultation.
							</p>
						</>
					}
				</div>

			)
		}

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	/*show_tiles_sect = (btns, hdr, col) => {

		return <div className='md:p-6'>
			<h3 className={cls(`text-${col}`,'mb-4')}>{hdr}</h3>
			<div className='cards-list'>
			{
				btns
					.map( (el,i)=> <div
								className={cls("card group",el.t)}
								key={el.lnk}
								onClick={_=>gtm.productClick([this.to_gtm_product(el,i)],this.gtm_list(el))}
							>
								<Link 
									to={el.lnk}
									className="flex flex-grow p-4"
								>
									<div className='flex flex-grow flex-col '>
											<div className='text-gray-800 flex items-center'>
												<h2>{el.n.toLowerCase()}</h2>
											</div>
										<p className='flex-grow mb-0 mt-2 text-gray-500'>{el.d}</p>
									</div>
									<ArrowIcon/>
								</Link>
							</div>
						)
			}
			</div>
		</div>
	}*/

	// 		--------------------------------		--------------------------------		---------
				  
	/*cat_top_btns = () => {

		const top_btn_data = [
			{
				lnk: "/c/pharm/womens",
				d: "Contraception, Hormone Replacement Therapy, Nausea & Morning Sickness, Urinary Tract Infections",
				n: "Women's Health",
				t: "c",
			},
			{
				lnk: "/c/pharm/mens",
				d: "Erectile Dysfunction, Hair Loss, Premature Ejaculation and Prostate Health.",
				n: "Men's Health",
				t: "c",
			},
			{
				lnk: "/c/pharm/-LJVyR5Pdls_ydzW8sRk",
				d: "Asthma relievers and preventers including Ventolin, Seretide and Symbicort",
				n: "Asthma",
				t: "c",
			},
			{
				lnk: "/c/pharm/-LJlvQo2RunU9oi9abk5",
				d: "Medication used to treat hypertension of high blood pressure",
				n: "Blood Pressure",
				t: "c",
			},
			{
				lnk: "/c/pharm/-LJcGNXMyADt_gxi2m-f",
				d: "Cholesterol lowering agents for people suffering from high blood cholesterol levels",
				n: "Cholesterol",
				t: "c",
			},
			{
				lnk: "/c/pharm/-LMrOQSVQMBVgUqe3y2A",
				d: "SSRIs used to treat depression and/or anxiety disorders",
				n: "Depression and Anxiety",
				t: "c",
			},
		]
		
		return this.show_tiles_sect(top_btn_data, 'Popular Medicine Categories', 'is-blue')
	}*/
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	/*operating_bnrs = (lg) => {
		const now_open = app.settings.now_open;
		return  (<div className='ui container py-2'>
					{!now_open && <Alert afterHr className="my-2" header={<p>Sorry we are closed. If it is an emergency contact 000.</p>}/> 
					|| lg && <LoginBanner clsn="mt-2" /> || null}
				</div>)
	}*/

	// 		--------------------------------		--------------------------------		---------

	tile_clicked = () => {
		this.setState({srch_str:""})
	}

	// 		--------------------------------		--------------------------------		---------

	/*consult_tiles = (data, title) => {
		const { med_dets } = this.state
		const cat_loc = this.props.match.params.cat_route || ''
		const srch_str = this.state.srch_str?.trim()
		
		return <>{title && <h3 className='mt-4 mb-2'>{title}</h3>}
		<div className="w-full border-t border-gray-200 mt-2 sm:mb-4 md:mb-6 " /><div className='cards-list'>
		{
			data.map( (el,i)=> {
				const med = med_dets.filter(m => el.k == m?.m)
				const cost = med[0]?.mc_pr
				const info_link = med[0]?.IL
				const to = {
					pathname:el.lnk || 
								(
								(el.t=='c' ? app.consts.routes.cat_route : el.t=='m' ? app.consts.routes.med_route : '/err') 
								+ "/"
								+ (this.state.cat_nm)
								+ "/"
								+ (srch_str ? '' : cat_loc + (!cat_loc || cat_loc.endsWith('/') ? '' : '/'))
								+ (srch_str&&el.l ||'')
								+ el.k),
					state : {
						srch_str
					}
				}
				return <div
						className={cls("card group", el.t)}
						key={`cat_it_${cat_loc}_${i}`}
						onClick={_=>gtm.productClick([this.to_gtm_product(el,i)],this.gtm_list(el))}
						>   <Link 
								to={to}
								className="flex flex-grow p-4"
							>
								<div className='flex flex-grow flex-col '>
									<Header as="h5">{el.n}</Header>
									<div className="w-full border-t border-gray-200 mt-2"/>
									<p className='text-gray-500 flex-grow mb-0 mt-2'>{el.d}</p>
									<div className='cat-delivery'>
										{cost && <span className="tile-cost"><Badge small className='mt-4 mr-2'>
											Cost: ${cost}
										</Badge></span>}<br/>
									</div>
								</div>
							</Link>

							<Buttons cta="Request a Consultation" info_link={info_link} el={el} cta_link={to} />
						</div>
						}
					)
		}
	</div>
	</>
	}*/

	// 		--------------------------------		--------------------------------		---------

	/*render_treatment_plans() {
		const { cats_show_data } = this.state

		if (!cats_show_data)
			return null

		return (
			<React.Fragment>

				<Helmet>
					<title>Treatment Plan Consultations</title>
				</Helmet>
				<div id="cat-info">
					<p className='text-gray-500 lg:w-4/5 mb-6'>
						We offer some medications as a repeat prescription with regular home delivery for extra
						convenience and ease. Speak to one of our doctors today for a Treatment Plan.
					</p>
					{this.operating_bnrs(true)}
				</div>

				{this.consult_tiles(cats_show_data)}

			</React.Fragment>
		)
	}*/

	/*render_consults() {
		
		const { cats_show_data } = this.state

		if (!cats_show_data)
			return null

		const [general, spec] = cats_show_data.reduce(([gen, sp], el) => (["DocConsCov", "DocConsFU"].includes(el.k) ? [[...gen, el], sp]:[gen, [...sp, el]]), [[], []] );

		return <React.Fragment>
			
					<Helmet>
						<title>Consultations</title>
					</Helmet>
					<div id="cat-info">
						<p className='text-gray-500 lg:w-4/5 mb-6'>Need to speak to a doctor over the phone? Get the medical advice you need from anywhere in Australia. Our team of Australian doctors can provide you fast and easy to access Telehealth consulting services in Australia.</p>
						{this.operating_bnrs(true)}
					</div>

					{this.consult_tiles(general, 'General')}

					{this.consult_tiles(spec, 'Specialised')}
					
				</React.Fragment>
	}*/
	
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render () {

		const { cat_data_db, data_loaded, srch_str, cat_nm, view_url } = this.state
		const cat_loc = this.props.match.params.cat_route || ''
		
		const base_path = `${app.consts.routes.cat_route}/${cat_nm}/`

		const cat_rec = cat_data_db?.[cat_loc];
		const current = cat_rec?.n?.toLowerCase()
															.split(' ')
															.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
															.join(' ');
		
		const cat_code = cat_loc.split('/').pop()

		const medcerts = cat_loc === 'certs'

		const lp = ['-LJgjeZExUeqwdPu6EuP', '-LJVyR5Pdls_ydzW8sRk', '-LOJ8nO6CQ9dJLu7lTIo', 'contraception', 'hrt', '-Mb9Djo698wxjv2kmMq6', 'nausea', 'uti', 'erectile-dysfunction', 'hair', 'premature-ejaculation', '-M3OqPH3Ur6-IUIrPV6c', '-LJlvQo2RunU9oi9abk5',
			'-LJcGNXMyADt_gxi2m-f', '-M34DQ_Bi2Qs7693U5-H', '-LtgfWyrIsquO-71lRp3', '-LMrOQSVQMBVgUqe3y2A', '-NR_wwrgDAWpSCRnQS4u', '-LJw1ii8QN0d9voiNYi3', '-M2kr-X-SGFjWTib-pYM', '-LJVyeBvRW_BnUCsx0kw', '-LStHA4fgdwARB_sds0x', '-LTwg2i8tlAHzQp_24d2',
			'-LJn8ZK_ufxt8fWpDyl8', '-LXa3IJ_uqU-Tc348e1S', '-LTmNvHxxWf4NuDd8rMn', '-LTLTLfVHJG0fyp2GO44', '-LMfGlpF3oP3xgL52d7w', '-LQ-1WaFZZIdgfFLBsZC', '-LwGrL-z9Tx_cVxQH088', '-LJcNCNA4ONtItNNzXB0', '-LUTBLS-EhdO6PD5Z_Tb', '-LJgoXQ-7pafIrsSQXZ7', '-N9YuK8ZZsDznRtdISNF',
			'acne', 'rosacea', 'ibs', 'herpes', 'eczema', '-N1fTGgW2ONhP0Zurrnn', 'womens', 'mens'].includes(cat_code);
			
		return <React.Fragment>
			
					<Helmet>
						<title>{current || 'All Categories'}</title>
					</Helmet>
					
					{cat_nm == 'pharm' && !medcerts && <div className='border-b border-is-gray-50 bg-white md:px-6 md:py-8 px-0 py-4'>
						<Container>
						{!app.settings.iscr2 && !cat_loc && <><Breadcrumb
											className='catalog'
											curr_name='All Conditions'
											root_name='Home'
											nav_base='/'
										/>
										<h1 className="text-xl">Health Conditions</h1>
									</>
						}
						{cat_loc && <>
							<div className="cat-search md:border-gray-200 gap-2 text-center max-w-[600px] mx-auto">
								<Input
									ref={this.searchRef}
									value={srch_str || ''}
									icon={<div className='search-icon'><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.59842 2.80002C4.94746 2.80002 2.79843 4.94906 2.79843 7.60002C2.79843 10.251 4.94746 12.4 7.59842 12.4C10.2494 12.4 12.3984 10.251 12.3984 7.60002C12.3984 4.94906 10.2494 2.80002 7.59842 2.80002ZM0.398438 7.60002C0.398438 3.62357 3.62198 0.400024 7.59842 0.400024C11.5749 0.400024 14.7984 3.62357 14.7984 7.60002C14.7984 9.15502 14.3054 10.5949 13.4673 11.7719L19.2469 17.5515C19.7155 18.0201 19.7155 18.7799 19.2469 19.2486C18.7783 19.7172 18.0185 19.7172 17.5499 19.2486L11.7702 13.4689C10.5933 14.3071 9.15341 14.8 7.59842 14.8C3.62198 14.8 0.398438 11.5765 0.398438 7.60002Z" fill="#111827" /></svg></div>}
									iconPosition='left'
									data-testid='input-search'
									className="text-is-black-50"
									style={{ margin: '0 auto', minHeight: "44px", borderRadius: '32px' }}
									placeholder={this.state.placeholder}
									onChange={(event, cats_show_data) => this.setState({ srch_str: cats_show_data.value })}
								/>
							</div>
							{data_loaded && srch_str && <div className={cls("mt-4 bg-white p-6 md:p-0")}>
									{this.cats_list(true)}
							</div>}
						</>}
						</Container>
					</div>}

					{!app.settings.iscr2 && cat_loc && <div className={cls("bg-white border-b border-is-gray-50 py-8", medcerts && 'border-t')}>
                        <Container className={!srch_str ? 'cat-cards' : ''}>
                            <Breadcrumb
                                className={cls(!lp && 'mb-2', 'catalog')}
                                nav_base={base_path}
                                nav_route = {cat_loc}
                                curr_code = {cat_code}
                                curr_name = {cat_loc && cat_loc!='' && <KeyName
                                loc={cat_loc}
                                db_get = { (l, ca)=>{
                                    cat_model.watch_record(l, ca)
                                } }
                                /> }
                                root_name = {app.settings.icosm ? 'Catalogue' : 'All Conditions'}
                                db_get = { (l, ca)=>cat_model.watch_record(l, ca) }
                                curr_remove={true}
                            />
                        </Container>
                    </div>}

					<Container className='cat-cards' fluid={lp || (!cat_loc && cat_nm === 'pharm')}>
				        <div className={cls('md:rounded-md', (lp || !cat_loc) ? 'mt-0' : 'mt-4 md:mt-6')}>

							{(!srch_str || cat_nm !== 'pharm') && 
							<>
								<div className={cls(lp && "bg-white pt-4 md:pt-6")}>
									<div className="cat-search md:border-gray-200">
								 	{(!lp && cat_loc != '' || cat_nm != 'pharm') && <div className={cls(medcerts ? "mb-2 mt-4" :  "mb-2 md:mb-6 mt-4", "gap-2")}>

											<h1 className={cls("text-xl md:text-[2.5rem] md:leading-[3rem]", medcerts && 'text-center mb-2')}>
												{cat_loc && <KeyName
													// k={l.k}
													loc={cat_loc}
													db_get={(l, ca) => {
														cat_model.watch_record(l, ca)
													}}
												/> || cat_nm == 'patho' && 'Pathology Category' || cat_nm != 'pharm' && 'Medicine Category'}
											</h1>
											{cat_loc === "certs" && <p className={medcerts ? "text-center" : ""}>Complete our online form which will be certified by one of our doctors. <br/><span className="italic text-is-black-50">Please note, the medical certificate will be issued for today. We cannot backdate certificates.</span></p>}
										</div>}
										{cat_rec?.f?.cat_warn && <Container><Alert header={cat_rec?.f?.cat_warn} warning className='mt-4 mb-0' /></Container>}
									</div>
								</div>
							
								{lp ? <LandingPage url={cat_code} /> :
									<>
										{!data_loaded && <div className="md:p-2 ui container">Loading...</div>}

										{data_loaded && (cat_loc || cat_nm != 'pharm') && this.cats_list()}

									</>
								}
							</>}

					    {data_loaded && cat_nm === 'pharm' && <ConditionsAndServicesTiles showCommon={!cat_loc} showOther={!cat_loc} showServices={srch_str || !cat_loc} tileClicked={this.tile_clicked}/>}
							
						</div>

						{view_url && <Modal open={true}
							onClose={_ => { this.setState({view_url:false}) }}
							closeIcon>
							<Modal.Header>
								Consumer Medicine Information – TGA
							</Modal.Header>
							<Modal.Content>
								<iframe src={view_url} style={{ width: '100%', height: '70vh' }} frameBorder="0"></iframe>
							</Modal.Content>
						</Modal>}
					</Container>
			</React.Fragment>
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

}

const CatExp = (props) => {

	return <Cat {...props} />;
}

export default CatExp;

CatExp.access = (props) => {
	const cat_nm = props.alt_cat?.replace(/^_/, '') || props.match.params.cat_nm || 'pharm';
	return user_model.limit_access('pg_cat', cat_nm, true, app.history.location.pathname );
}