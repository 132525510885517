import React, { useCallback, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import scripts_list_model from '../../models/scripts_list_model';
import { DataConnector, ListPagination } from '../../xAppLib/DataTable';
import { useProfile } from '../../xAppLib/Hooks';
import FamilySelector from '../../components/FamilySelector';
import { Header } from '../UIelems/v2/Header/Header';
import ActivePrescriptions from '../../components/medications/ActivePrescriptions';
import FilledPrescriptions from '../../components/medications/FilledPrescriptions';
import {filterActivePrescriptions, isActivePrescription} from "@/helpers/scripts";

export const capitalizeFirstLetter = (text) => {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};

export const trimMedicationName = (medicationName, strength) => {
	if (medicationName && strength && medicationName.trim().endsWith(strength.trim())) {
		return medicationName.replace(new RegExp(`${strength}\\s*$`), '').trim();
	}
	return medicationName;
};

const getMedicationKey = (item) => {
	if (item?.epresc?.item?.AMT_MPP) {
		return item.epresc.item.AMT_MPP;
	}
	const escriptItem = item?.epresc?.item || {};
	const medData = item?.med_db_data || {};
	const strength = escriptItem.ItemStrength || '';
	const brandName = trimMedicationName(capitalizeFirstLetter(escriptItem.ItemTradeName), strength) || '';
	const genericName = trimMedicationName(escriptItem.ItemGenericName, strength) || '';
	const size = medData.size || '';
	const qnty = medData.qnty || '';

	return `${brandName}|${genericName}|${strength}|${size}|${qnty}`.toLowerCase();
};

export default function Medications() {
	const { sid } = useParams();
	const [prof, allProfiles] = useProfile();
	const [uid, setUid] = useState(prof?.uid);
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [showProfileNames, setShowProfileNames] = useState(false);
	const [refresh, setRefresh] = useState(new Date().getTime());

	const handleOnSelectProfile = useCallback((profile) => {
		setUid(profile?.uid);
		setShowProfileNames(profile?.uid === null || profile?.uid === undefined);
		setRefresh(new Date().getTime());
	}, []);

	return (
		<div className="!h-full">
			<Header title="Medications">
				{allProfiles.length > 1 && (
					<FamilySelector 
						addAllOption 
						onSelect={handleOnSelectProfile} 
						value={uid} 
						header={""} 
					/>
				)}
			</Header>

			<section className="!px-4 !flex !flex-col !gap-6 !bg-white !min-h-[40vh] md:!bg-is2-gray-10">
				<DataConnector
					refresh={refresh}
					pageSize={pageSize}
					page={page}
					data={async () => {
						try {
							const results = await scripts_list_model.load_scripts_list({
								type: 'pts',
								total_count: true,
								get_stats: true,
								limit: pageSize,
								show_list: "medications",
								offset: (page - 1) * pageSize,
								filters: { sid, uid, escr_only: true, },
							});

							if (results.res === 'ok') {
								return { data: results.listScripts, total: results.total };
							} else {
								throw new Error("Unexpected response format");
							}
						} catch (error) {
							console.error("Error loading scripts list:", error);
							return { data: [], total: 0 };
						}
					}}
				>
					{(data, loading, pages, total, pageSize) => {
						if (loading) return <Loader active inline="centered" className="after:!border-t-gray-500" />;
						if (total == 0)
							return (
								<div className="flex flex-col w-full gap-2 py-20 text-center items-center bg-white mx-auto">
                                    <Link to="/m/pharm/online/prescription" className="ui button primary w-full sm:w-auto">
										Request new prescription
									</Link>
                                    <div className="mt-5 text-center !bg-white !border-gray-100 p-10 ">
                                        <p>Your script requests history is empty.</p>
                                    </div>
                                </div>
							);

						const renderActiveData = filterActivePrescriptions(data)

						const activeMedKeys = new Set(
							renderActiveData?.map((item) => getMedicationKey(item))
						);

						const renderDispensedData = data?.filter((item) => {
							// If it's active, don't show it in the dispensed list
							if(isActivePrescription(item)) return false;
							const key = getMedicationKey(item);
							return !activeMedKeys.has(key);
						});

						return (
							<section className="max-w-screen-lg mx-auto !bg-white md:py-5 md:px-14">
								<nav className="flex flex-col w-full gap-2 sm:flex-row mt-8 text-center items-center bg-white mx-auto">
									<Link to="/m/pharm/online/prescription" className="ui button primary w-full sm:w-auto">
										Request new prescription
									</Link>
								</nav>

								<ActivePrescriptions data={renderActiveData} loading={loading} showProfileNames={showProfileNames} columnsLg={3} />
								<FilledPrescriptions data={renderDispensedData} loading={loading} showProfileNames={showProfileNames} />

								<div className="flex flex-col gap-2 items-center justify-center py-3">
									<p className="flex text-center items-center my-auto">Check your History to view past prescriptions</p>
									<Link to="/history" className="ui button primary w-full sm:w-auto text-center">
										Go to History
									</Link>
								</div>

								<ListPagination
									page={page}
									pages={pages}
									pageSize={pageSize}
									loading={loading}
									data={data}
									total={total}
									boundaryRange={3}
									showPageSizeSelector={true}
									onPageSizeChange={setPageSize}
									onPageChange={setPage}
								/>
							</section>
						);
					}}
				</DataConnector>
			</section>
		</div>
	);
}
