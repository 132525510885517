import React from 'react';
import { Link } from 'react-router-dom';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';
import { cls } from '../../../NUI/utils';

export default function ServiceCard({ icon, title, description, price, href, onMenuChange, wide }) {
    return (
        <Link to={href} className={cls("bg-is2-blue-50 hover:bg-is2-blue-100/50 transition-colors duration-300 rounded-[20px] !px-4 lg:!px-[22px] !pt-6 !pb-4 lg:!py-6 text-center lg:text-left flex items-center flex-col", wide ? "lg:flex-row" : "lg:items-start")} data-testid="card-services" onClick={() => {onMenuChange(false); }}>
            {typeof icon === 'string' && <SIcon name={icon} alt={icon} size="md" cls="text-is2-blue mx-auto lg:mx-0 !h-[40px] !w-[40px]" bold />}
            {typeof icon !== 'string' && <div className="p-1 h-[40px] w-[40px] text-is2-blue mx-auto shrink-0 inline-block">{icon}</div>}
            <div className={cls(wide && "lg:w-[137px] lg:ml-5")}>
                <h5 className="text-base font-bold text-is2-gray-450 my-1 lg:text-left lg:mb-0 mt-3">{title}</h5>
                {!price?.includes('undefined') && <p className="text-sm font-normal text-is2-gray-150">{price}</p>}
            </div>
            <p className={cls("text-sm text-is2-gray-150 mb-2 lg:text-left border-t border-t-white mt-3.5 pt-3.5", wide && "lg:ml-3.5 lg:mt-0 pt-0 lg:border-none")}>{description}</p>
        </Link>
    )
}