import React from 'react';
import SIcon from '../../NUI/StreamlineIcons/SIcon';
import { cls } from '../../NUI/utils';

export function PaymentCard({card, edit, onEdit, clsn }){

	return (
			<div key={card.short+card.name} onClick={onEdit||null} className={cls("border flex items-center rounded-lg w-full border-is-gray-50 py-3 px-4", edit&& "transition-all duration-300 cursor-pointer hover:bg-is2-blue-50", clsn)}>
				<span className="shadow-sm rounded-sm flex justify-center mr-2 bg-white h-5 w-[30px] border border-gray-100">
					<img className="h-[10.97px] self-center" src={"https://storage.googleapis.com/instant-med-public/Mastercard.png"} alt="Mastercard Logo" />
				</span>
				<div className="flex flex-col text-sm">
					{card.name}<br/>
					{card.short} **** **** ****
					<small>{!card?.CC_tok?.token && <span className='text-red-600'>This card token is invalid. Please use a different card.</span>}</small>
				</div>
				{edit && <div className="cursor-pointer absolute right-4 h-5"><SIcon name="pencil" size="xxs" bold cls="cursor-pointer text-is2-gray-body"/></div>}
				
			</div>
	);
}

