import React from 'react';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';
import { Link } from 'react-router-dom';
import { cls } from "../../../NUI/utils";
import menu_model from '../../../../models/menu_model';
import { useProfile } from '../../../../xAppLib/Hooks';
import moment from "moment";

export function HeaderNav({ curr, sidebar = false }) {
    const menu = menu_model.get_headermenu();
    const [prof] = useProfile();
    const now = moment();

    const isPrescription = (item) => (
            (item.script_type === 'docscr' && item.req_type === 'doccons') ||
            (['qualbuy', 'qualcons', 'qualclick', 'medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick'].includes(item.script_type) &&
                ['premed'].includes(item.req_type))
        );

    const isCertificate = (item) => (
        item.script_type === 'medcert' || ['medcert', 'dcs_cert'].includes(item.req_type)
    );

    const isReferral = (item) => (
        (
            (item.script_type === 'doccons' && ['docconsref', 'dcs_refr'].includes(item.req_type)) ||
            item.script_type === 'docrefr'
        ) && item.status !== 'patho_res'
    );

    const isTestResult = (item) => (
        (
            (item.script_type === 'pathoreq' && ['doccons', 'premed', 'remed', 'docconswl', 'docconswli', 'docconswlo'].includes(item.req_type)) ||
            (item.script_type === 'doccons' && ['dcs_patho', 'docconsref', 'dcs_refr'].includes(item.req_type)) ||
            (item.script_type === 'docrefr')
        ) && item.status === 'patho_res'
    );

    const getNewItemCount = (history = [], filterFunc) =>
        history.filter(item => filterFunc(item) && moment(item.add_tm, 'D/M/YY HH:mm:ss').isAfter(now.clone().subtract(1, 'day'))).length;

    return (
        <div className={cls("p-6 lg:p-5 gap-2 grid grid-cols-2 lg:max-w-[744px] mx-auto lg:w-full bg-is2-blue-300 ", sidebar && "lg:grid-cols-1 lg:bg-transparent lg:gap-6")}>
            {menu.map((el, i) => {
                const typeMappings = {
                    patho: isTestResult,
                    medcert: isCertificate,
                    docrefr: isReferral,
                    script: isPrescription,
                };

                const check = typeMappings[el.type] || (() => false);
                const newItemCount = getNewItemCount(prof?.hist, check);

                return (
                    <Link to={el.link} key={el.link + i} className={cls(
                        (el.name === curr && `border-white ${sidebar && "lg:bg-is2-blue-300 lg:border-none"}`),
                        "relative flex flex-col px-3 py-1.5 rounded gap-2 border border-transparent hover:border-white items-center transition-border ease-in-out duration-150",
                        sidebar ? "lg:px-0 lg:flex-row lg:items-center lg:gap-1" : "lg:px-1.5 lg:py-3"
                    )}>
                        <SIcon name={el.icon} size="s" cls="text-white" bold />
                        <div className="flex">
                            <p className={cls("text-base leading-5 mb-0 mr-1 lg:mr-0", !sidebar && "text-center", newItemCount > 0 && "font-bold")}>
                                {el.name}
                            </p>
                            {newItemCount > 0 && (
                                <div className="bg-is2-orange text-xs rounded px-1 lg:absolute right-1.5">
                                    {newItemCount} Today
                                </div>
                            )}
                        </div>
                    </Link>
                );
            })}
        </div>
    );
}