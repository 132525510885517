import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * A functional component that renders a tabbed interface.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {{id: string, name: string, content: JSX.Element}[]} props.elements - An array of objects representing the tab elements.
 * Each object should contain a ⁠ name ⁠, ⁠ id ⁠, and ⁠ content ⁠.
 *
 * This component renders a header containing buttons for each tab, and a section
 * that displays the content of the currently active tab. Clicking a button updates
 * the active tab and moves a visual indicator ("menu-pointer") to the location of the
 * clicked button.
 */
export default function StepsTabs({ elements }) {
    const currentElement = useRef(elements[0].id);
    const [currentDisplayComponent, setCurrentDisplayComponent] = useState(
        elements[0].content
    );

    const handleResize = useCallback(() => {
        const wrapper = document.querySelector("#steps-tabs-wrapper");
        if (!wrapper) return;
        const tabsBox = document.querySelector("#tabs-component");

        const currentWindowWidth = window.innerWidth;

        const resizeObserver = new ResizeObserver(() => {
            const currentItem = document.querySelector(`#${currentElement.current}`);
            if (!currentItem) return;

            const wrapperBounds = wrapper.getBoundingClientRect();
            const { left, width } = currentItem.getBoundingClientRect();

            let elementMiddlePosition = left + (width - 40) / 2;

            if (currentWindowWidth > wrapperBounds.width) {
                const wrapperLeftOffset = wrapperBounds.left;
                elementMiddlePosition -= wrapperLeftOffset;
            }

            const menuPointer = document.querySelector("#menu-pointer");
            menuPointer.style.setProperty("--left", `${elementMiddlePosition}px`);
            menuPointer.style.setProperty("--top", `${0}px`);
        });

        resizeObserver.observe(tabsBox);

        return {
            unobserve: () => resizeObserver.unobserve(tabsBox),
        };
    }, []);

    useEffect(() => {
        const TIMEOUT_UNTIL_MOUNT = 1000;

        function handleMountFirstElement() {
            const wrapper = document.querySelector("#steps-tabs-wrapper");
            if (!wrapper) return;
            const wrapperBounds = wrapper.getBoundingClientRect();

            const currentWindowWidth = window.innerWidth;

            const targetElement = document
                .querySelector("#tabs-component")
                .querySelector(`#${currentElement.current}`);

            if (!targetElement) return;

            const { left, width } = targetElement.getBoundingClientRect();
            const menuPointer = document.querySelector("#menu-pointer");

            let elementMiddlePosition = left + (width - 40) / 2;

            if (currentWindowWidth > wrapperBounds.width) {
                const wrapperLeftOffset = wrapperBounds.left;
                elementMiddlePosition -= wrapperLeftOffset;
            }

            menuPointer.style.setProperty("--left", `${elementMiddlePosition}px`);
            menuPointer.style.setProperty("--top", `${0}px`);

            currentElement.current = targetElement.id;

            setCurrentDisplayComponent(
                elements.find((element) => element.id === targetElement.id).content
            );
        }

        handleMountFirstElement();

        const timeout = setTimeout(() => {
            handleMountFirstElement();

            clearTimeout(timeout);
        }, TIMEOUT_UNTIL_MOUNT);

        const { unobserve } = handleResize();

        return () => {
            unobserve();
        };
    }, [handleResize, elements, currentElement]);

    const handleElementClick = useCallback((e) => {
        e.target.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
        });

        const timeout = setTimeout(() => {
            const wrapper = document.querySelector("#steps-tabs-wrapper");
            if (!wrapper) return;
            const wrapperBounds = wrapper.getBoundingClientRect();

            const { left, width } = e.target.getBoundingClientRect();

            const currentWindowWidth = window.innerWidth;

            let elementMiddlePosition = left + (width - 40) / 2;

            if (currentWindowWidth > wrapperBounds.width) {
                const wrapperLeftOffset = wrapperBounds.left;
                elementMiddlePosition -= wrapperLeftOffset;
            }

            const menuPointer = document.querySelector("#menu-pointer");
            menuPointer.style.setProperty("--left", `${elementMiddlePosition}px`);
            menuPointer.style.setProperty("--top", `${0}px`);

            currentElement.current = e.target.id;

            setCurrentDisplayComponent(
                elements.find((element) => element.id === e.target.id).content
            );

            clearTimeout(timeout);
        }, 500);
    }, []);

    return (
        <div className="overflow-hidden bg-is2-yellow-100">
            <header
                id="tabs-component"
                className="z-40 flex items-center justify-center w-full py-3 text-black bg-is2-yellow-100"
            >
                <nav className="overflow-auto xl:block no-scrollbar">
                    <ul className="flex text-sm [&>button]:transition-colors [&>button]:duration-500 [&>button]:text-current [&>button]:font-medium [&>button]:inline-block [&>button]:px-4 [&>button]:py-2">
                        {elements.map((item) => (
                            <button
                                key={item.name}
                                className="flex-grow cursor-pointer text-nowrap text-[20px] leading-[28px] text-is2-gray-150"
                                onClick={handleElementClick}
                                id={item.id}
                            >
                                {item.name}
                            </button>
                        ))}
                    </ul>
                </nav>
            </header>

            <section className="relative w-full p-6 text-white bg-is2-blue rounded-2xl md:mt-2">
                <div
                    id="menu-pointer"
                    className={`
                        absolute p-5 transform rotate-45 
                        bg-is2-blue 
                        translate-x-[var(--left)] translate-y-[var(--top)]
                        left-0 top-0 transition-all duration-500 ease-in-out 
                    `}
                ></div>
                {currentDisplayComponent}
            </section>
        </div>
    );
}

StepsTabs.propTypes = {
    elements: PropTypes.array.isRequired,
};