import React from "react";
import { Dropdown } from "semantic-ui-react";

export function SiteVariationSelector() {
	if (!app.settings.dev_env) {
		return null;
	}

	const variations = [
		// runt.js needs to be updated so that it checks sticky value to enable the appropriate theme
		{
			name: 'IS2.0',
			value: 'iscr2',
		},
		{
			name: 'Cosmetics',
			value: 'icosm',
		},
		{
			name: 'default',
		},
	].map(variant => ({
		...variant,
		enable: () => {
			variant.value
				? localStorage.setItem('__runt.sticky', variant.value)
				: localStorage.removeItem('__runt.sticky');
			window.location.reload();
		},
		active: app.runtime.theme ? app.runtime.theme === variant.value : app.runtime.app === (variant.value ?? 'iscr'),
	}));

	const current = variations.find((v) => v.active);

	return (
		<Dropdown text={current?.name ?? 'unknown'} className="site-variation">
			<Dropdown.Menu>
				{variations.map((v) => (
					<Dropdown.Item key={v.name} onClick={v.enable} active={v.active}>
						{v.name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}