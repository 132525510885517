import React from 'react';

export default function TrophySVGIcon({ width = 40, height = 40, fill = "transparent" }) {

    return (

        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 80" fill="none">
            <g clipPath="url(#clip0_1274_159)">
                <path d="M24.25 60.25H55.75C55.75 60.25 60.25 60.25 60.25 64.75V69.25C60.25 69.25 60.25 73.75 55.75 73.75H24.25C24.25 73.75 19.75 73.75 19.75 69.25V64.75C19.75 64.75 19.75 60.25 24.25 60.25Z" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M28.75 6.25H9.319C8.50505 6.25 7.72444 6.57334 7.14889 7.14889C6.57334 7.72444 6.25 8.50505 6.25 9.319C6.25 18.2701 9.80579 26.8545 16.1351 33.1839C22.4645 39.5132 31.0489 43.069 40 43.069C48.9511 43.069 57.5355 39.5132 63.8649 33.1839C70.1942 26.8545 73.75 18.2701 73.75 9.319C73.75 8.50505 73.4267 7.72444 72.8511 7.14889C72.2756 6.57334 71.4949 6.25 70.681 6.25H51.25" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M33.646 42.472L28.417 60.25H50.917L46.189 42.502" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M41.8 7.22494L45.544 14.5989H51.925C52.2756 14.5919 52.6202 14.6904 52.9141 14.8817C53.2081 15.073 53.4377 15.3483 53.5732 15.6717C53.7087 15.9951 53.7439 16.3518 53.6741 16.6955C53.6044 17.0392 53.4329 17.3539 53.182 17.5989L47.314 23.3559L50.56 30.8109C50.7028 31.1526 50.7346 31.5305 50.651 31.8912C50.5674 32.2519 50.3726 32.5772 50.0941 32.8212C49.8156 33.0653 49.4675 33.2156 49.0989 33.2511C48.7303 33.2866 48.3599 33.2054 48.04 33.0189L40.195 28.5999L32.35 33.0189C32.0301 33.2042 31.6602 33.2845 31.2923 33.2485C30.9243 33.2125 30.577 33.062 30.2991 32.8183C30.0212 32.5745 29.8267 32.2497 29.7431 31.8896C29.6595 31.5295 29.691 31.1522 29.833 30.8109L33.079 23.3559L27.22 17.5899C26.9689 17.3448 26.7974 17.0297 26.7277 16.6858C26.658 16.3418 26.6934 15.9848 26.8293 15.6613C26.9652 15.3377 27.1953 15.0625 27.4897 14.8714C27.7841 14.6804 28.1291 14.5823 28.48 14.5899H34.858L38.605 7.21594C38.7577 6.92383 38.9877 6.67926 39.2699 6.50891C39.5521 6.33855 39.8756 6.24896 40.2052 6.24989C40.5349 6.25081 40.8579 6.34223 41.1391 6.51417C41.4203 6.68612 41.6489 6.93197 41.8 7.22494Z" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1274_159">
                    <rect width="72" height="72" fill={fill} transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}