import React from 'react';
import { cls }  from "../../NUI/utils";

export function Divider({children, color, margin, clsn}) {

    const clr = color === 'blue' && 'text-is2-blue-250' || color === 'gray' && 'text-is2-gray-50' || color === 'white' && 'text-white' || color === "darkblue" && "text-is2-blue-850" || 'text-is2-blue-650';
	
	return  <div className={cls(clsn, 'divider relative w-full', clr, margin || 'my-9')}>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className={`w-full border-t border-current`} />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm">{children}</span>
                </div>
            </div>
}
