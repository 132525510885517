export const PARTN = {
	PA: 'Zz50m1I',
	DUKASA: 'iD_cmXA',
	BLS: 'szgbqzk',
	C2U: 'qrGpLwI',
	EVERMED: 'vkHODYE',
	ADORA: 'eAIt8fU',
}

export const DOCID_NODR = '-NMXGoMnCzUVo-XIzLwy'

export const MAX_K10_SCORE = 29; // 30 or higher, and we cannot provide async services; a consult is required

export const SECOND = 1000;