import React, { useMemo } from "react";
import { useSiteStatus } from "@/xAppLib/Hooks";
import { obj_val } from '@/helpers/json_functions';

const DEBUG = false;

export function SiteToggle({children, path, withProps, ...props}) {
	const status = useSiteStatus();
	const value = useMemo(() => obj_val(status, path.split('/')), [status, path]);

	const selected = React.Children.map(children, (element) => {
		DEBUG && console.log("variant", element.props.variant, value, value === element.props.variant);
		if (value === element.props.variant || (value === undefined && element.props.defaultVariant)) {
			return withProps
				? React.cloneElement(element, props)
				: element;
		}
	}).filter(Boolean);

	return (
		<>
			{DEBUG && <>{path}/{value}</>}
			{selected}
		</>
	);
}

const SiteToggleVariant = ({children}) => {
	return children;
};

const SiteToggleVariantFunc = ({children}) => {
	return children?.();
};

SiteToggle.Variant = SiteToggleVariant
SiteToggle.VariantFunc = SiteToggleVariantFunc

export default SiteToggle;
