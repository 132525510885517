import React, {useContext} from "react";
import { Form, FormInput } from "semantic-ui-react";
import {useForm} from "../Hooks/useForm";
import UniFieldSUI from "./UniField-SUI";
import UniForm from "./UniForm";

const FormContext = React.createContext(null);

/**
 * @param {React.PropsWithChildren<Omit<UseFormConfig, 'submit'>> & { onSubmit: UseFormConfig.submit; fields: any[] }} props
 * @return {Element}
 * @constructor
 */
export function FormProvider(props) {
	const {children, fields, onSubmit, ...formProps} = props;

	const form = useForm({submit: onSubmit, ...formProps});

	return (
		<Form onSubmit={form.onSubmit}>
			<UniForm {...form} alwaysCheck fields={fields}>
				{(values, valids, uf_this, fields) => (
					<FormContext.Provider value={{form, values, valids, uf_this, fields}}>
						{children}
					</FormContext.Provider>
				)}
			</UniForm>
		</Form>
	);
}

export function FormContextProvider({children, fields, form, ...props}) {
	return (
		<UniForm {...form} alwaysCheck fields={fields} {...props}>
			{(values, valids, uf_this, fields) => (
				<FormContext.Provider value={{form, values, valids, uf_this, fields}}>
					{children}
				</FormContext.Provider>
			)}
		</UniForm>
	);
}

export function useFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useUniFormContext must be used within a UniFormProvider');
	}

	const onChange = (name, value) => context.uf_this.handleInputChange({target: {name, value}});

	return {...context, onChange};
}

export function useFormField(name) {
	const context = useFormContext();

	const setValue = value => context.onChange(name, value);
	const resetValue = () => setValue('value' in context.fields[name] ? context.fields[name].value : null);
	const value = context.values[name];

	if (!context.fields[name]) {
		throw new Error(`Field "${name}" not found in form fields`);
	}

	return {
		...context,
		value,
		setValue,
		resetValue,
		input: {
			...context.fields[name],
			name,
			value,
			setValue,
			onChange: context.uf_this.handleInputChange,
			required: typeof context.fields[name].required === 'function'
				? context.fields[name].required(context.values)
				: Boolean(context.fields[name].required),
			error: context.form.showErrors && context.valids[name] === false
				? context.form.validsErrors[name]
				: undefined,
			...(context.fields[name].pattern instanceof RegExp&& {
				pattern: context.fields[name].pattern.toString().slice(1, -1),
			})
		},
	};
}

function BoundComponent({name, as: Component, ...props}) {
	const {input} = useFormField(name);

	return <Component {...input} {...props} />;

}

function BoundUniFieldSUI(props) {
	const {values, valids, uf_this} = useFormContext();

	return <UniFieldSUI {...{values, valids, uf_this}} {...props} />;
}

export function BoundField(props) {
	if ('fl' in props) {
		return <BoundUniFieldSUI {...props} />;
	}

	return <BoundComponent as={FormInput} {...props} />;
}
