import React from 'react';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';
import { Link } from 'react-router-dom';
import { cls } from "../../../NUI/utils";
import menu_model from '../../../../models/menu_model';
import Tooltip from './Tooltip';
import { Divider } from '../Divider';

export function HomeMenu() {
    const menu = menu_model.get_servicesmenu();
    const list = menu[0].items.concat(menu[1].items.filter((el) => !['Sexual health'].includes(el.title)));

    const firstRow = list.slice(0, 6);
    const secondRow = list.slice(6);

    const MenuItems = (el, i) => (
        <Tooltip key={i} content={
            <>
                {el?.description}
                <Divider margin="my-3"/>
                {el?.price}
            </>
        }>
            <Link to={el.href} className="flex flex-row xl:flex-col items-center gap-2 xl:gap-1 p-2 xl:p-3 xxl:px-5 w-full relative max-w-fit">
                {el?.new && (
                    <span className="absolute -top-1 xl:top-3 right-0 xl:right-3 bg-is2-green text-xs xl:text-sm px-1.5 xl:px-2 rounded w-fit !text-black">
                        New
                    </span>
                )}
                <SIcon 
                    name={el.icon} 
                    size="xs"
                    cls={cls("text-is2-gray-150 group-hover:text-is2-gray transition-all duration-300 ease-in-out xl:hidden")}
                    bold 
                />
                <SIcon 
                    name={el.icon} 
                    size="sm"
                    cls={cls("text-is2-gray-150 group-hover:text-is2-gray transition-all duration-300 ease-in-out hidden xl:block")}
                    bold 
                />
                <p className="text-is2-gray-150 text-sm xl:text-[18px] xl:leading-[20px] font-medium xl:text-center xl:max-w-[140px]">
                    {el.title}
                </p>
            </Link>
        </Tooltip>
    );

    return (
        <div className={cls("home-menu relative hidden lg:flex justify-center bg-white py-2 w-full border border-bottom border-is2-gray")}>
            <div className="flex flex-col w-full max-w-[1920px] mx-auto px-3 py-2 xl:hidden">
                <div className="flex justify-center w-full">
                    {firstRow.map(MenuItems)}
                </div>
                <div className="flex justify-center w-full mt-2">
                    <div className="flex gap-4 px-4">
                        {secondRow.map((el, i) => MenuItems(el, i + 6))}
                    </div>
                </div>
            </div>
            <div className="hidden xl:flex justify-between max-w-[1920px] w-full mx-auto px-3 xxl:px-6 py-2">
                {list.map(MenuItems)}
            </div>
        </div>
    );
}
