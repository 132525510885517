import React from "react";

/**
 * A component that renders an info home card for the homepage.
 * This component renders a card with a heading intro, title, image, and descriptions.
 * The image is displayed as a background image on larger screens, and as a foreground
 * image on smaller screens. The descriptions are rendered as a list of paragraphs.
 * If the postDescription prop is provided, it is rendered as a small text block below
 * the descriptions.
 * @param {string} icon - an optional icon to display above the title
 * @param {string} headingIntro - the heading intro text
 * @param {string} title - the title text
 * @param {string} img - the image URL
 * @param {Array<string>} descriptions - a list of description paragraphs
 * @param {string} postDescription - an optional post description text block
 * @param {boolean} isEven - whether the card is even or odd numbered
 * @returns {React.ReactElement} a React component that renders the info home card
 */
export default function InfoHomeCard({ icon, headingIntro, title, img, descriptions, postDescription, isEven }) {

    return (
        <article className={`flex flex-col gap-2 sm:gap-4 pb-9 ${isEven ? 'sm:flex-row-reverse' : 'sm:flex-row'}`}>
            <div className={`md:flex-[7] md:basis-7/12 py-auto ${isEven ? 'flex sm:justify-end' : ''}`}>
                <img
                    src={img}
                    alt={title}
                    className="hidden md:block w-full h-full aspect-[3.5/3] rounded-[40px] object-cover"
                    loading="lazy"
                />
            </div>
            <div className="md:flex-[5] md:basis-5/12 flex flex-col sm:bg-white justify-center text-center rounded-[40px] md:p-14">
                {icon && <div className="mb-9 mx-auto lg:hidden">
                    <img src={icon} />
                </div>
                }
                <span className="text-[18px] leading-[26px] text-is2-gray-350">{headingIntro}</span>
                <h3 className="text-[44px] leading-[47px] font-medium text-is2-gray-800 mb-9 mt-2 md:mb-12 mx-5 md:mx-0">{title}</h3>
                <img
                    src={img}
                    alt={title}
                    className="object-cover w-full h-full aspect-[3/3.5] block md:hidden rounded-[40px] mb-6"
                    loading="lazy"
                />
                {descriptions.map((description, index) => (
                    <p key={index} className="text-is2-gray-350 mb-6 mx-6" dangerouslySetInnerHTML={{ __html: description }}></p>
                ))}
                {postDescription && (
                    <span className="text-xs text-is2-gray-350 mb-2 mx-6">{postDescription}</span>
                )}
            </div>
        </article>
    )
}