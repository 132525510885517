import React from 'react';
import { FormCheckbox, FormInput } from "semantic-ui-react";
import { BoundField, FormContextProvider } from "xAppLib/UniForm/FormProvider";
import user_model from "../../../models/user_model";
import { toDictionary } from "../../icosm/treatment/utils";
import { Card } from "../../UIelems/Card";
import { Questions, QuestionsField } from "./Questions";

const SIGNUP_FIELDS = toDictionary(user_model.PROF_FIELDS_SGUP, f => f.name);

const FIELDS = [
	{...SIGNUP_FIELDS.first_name, valid_not_required: false},
	{...SIGNUP_FIELDS.last_name, valid_not_required: false},
	{...SIGNUP_FIELDS.dob, valid_not_required: false},
	{...SIGNUP_FIELDS.sex, valid_not_required: false},
	{...SIGNUP_FIELDS.atsi, valid_not_required: false},
	{
		name: 'privacy',
		label: 'Privacy',
		type: 'checkbox',
		valid_not_required: ({privacy}) => privacy === true,
	},
	{
		name: 'have_concession',
		label: 'Do you have a concession card?',
		options: [
			{text: 'Yes', value: 'yes', key: 'yes'},
			{text: 'No', value: 'no', key: 'no'},
		],
	},
	{
		name: 'have_dva',
		label: 'Do you have a Department of Veterans Affairs (DVA) card?',
		options: [
			{text: 'Yes', value: 'yes', key: 'yes'},
			{text: 'No', value: 'no', key: 'no'},
		],
	},
];

export function CompleteProfileForm({form}) {
	return (
		<FormContextProvider form={form} fields={FIELDS}>
			<Card mobile>
				<h3>Your details</h3>
				<BoundField name="first_name" as={FormInput}/>
				<BoundField name="last_name" as={FormInput}/>
				<BoundField name="dob" as={FormInput} type="date"/>
				<Questions>
					<BoundField name="sex" as={QuestionsField}/>
					<BoundField name="atsi" as={QuestionsField}/>
				</Questions>
				<BoundField name="privacy" as={FormCheckbox}/>
			</Card>

			<Card mobile>
				<h3>Concessions</h3>
				<Questions>
					<BoundField name="have_concession" as={QuestionsField}/>
					<BoundField name="have_dva" as={QuestionsField}/>
				</Questions>
			</Card>
		</FormContextProvider>
	);
}