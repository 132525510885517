import React from "react";
import { cls } from "../../NUI/utils";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";


export function TextImageBox({title, desc, img, id, content, primaryBtn, link, secondaryBtn, secondaryLink, color, newFeature, clsn, col, onDismiss, wide}) {
                   
	return  (
        <div className={cls("flex flex-col relative justify-center md:min-h-[248px] mx-auto", color ? "text-white bg-is2-blue" : "bg-white text-black", clsn, col && "lg:mb-4 lg:flex-col lg:w-full gap-6 pt-14 pb-12 md:p-6 px-4 md:p-0 md:rounded-[28px]" || wide && "md:w-full md:rounded-[4px] p-4" || "md:justify-start md:w-[calc(50%-12px)] mb-[44px]" )}>

            {newFeature && <span className="bg-is2-green text-sm px-2 rounded w-fit h-fit">New</span>}

            <div className={cls(col ? "order-2" : "order-1", wide ? "lg:absolute right-4 lg:w-[336px]": "w-full")}>
                {img ? <div className="rounded-[28px] h-[220px] bg-cover bg-center bg-no-repeat shrink-0" style={{backgroundImage: `url(https://storage.googleapis.com/instant-med-public/v2/home-whats-new/${img})`}}></div> : content}
            </div>

            <h2 className={cls(col? "order-1 m-0" : "mt-[38px] mb-3 order-2", "text-[20px] leading-[24px]")}>{title}</h2>

            <p className={cls("order-3 text-is2-gray-body my-0 text-base leading-[20px]", wide && "md:w-1/2")}>{desc}</p>

            <div className={cls(!col && "mt-6", "order-4 flex gap-4 ")}>
                <Button basic as={Link} to={link} className="w-full md:w-fit">{primaryBtn}</Button>
                {secondaryBtn && <Button secondary as={Link} to={secondaryLink} onClick={() => !secondaryLink && onDismiss(id)} className="w-full md:w-fit !text-is2-gray-700">{secondaryBtn}</Button>}
            </div>

        </div>
    )
}
