import React from 'react';
import {cls} from "../NUI/utils";

export function Card({children, className, mobile}) {
	return (
		<div className={cls(
			'md:bg-card md:rounded-md mt-4 md:p-4 md:shadow-md',
			mobile && 'bg-card rounded-md mt-4 p-4',
			className
		)}>
			{children}
		</div>
	)
}