import React, {useContext} from 'react'
import {EcommerceDataContext} from "../../../xAppLib/context";
import {useNavigate} from "react-router-dom";
import {get_cart_item_count} from "../EcommUtils";
import ShopAccessWrapper from "./ShopAccessWrapper";
import {ShoppingCartIcon} from "@heroicons/react/outline";

const CartIconButton = ({onlyShowWhenPopulated = false}) => {
    const {cart} = useContext(EcommerceDataContext)
    const navigate = useNavigate()
    const items = cart?.items || []

    if(onlyShowWhenPopulated && !items.length) return <></>

    return (
        <ShopAccessWrapper showMessage={false}>
            <button
                type="button"
                id={'cart-icon-button'}
                onClick={_ => navigate('/prescription-checkout')}
                className="relative inline-flex items-center p-3 text-sm font-medium text-center text-inherit"
            >
                <ShoppingCartIcon className={'w-5 h-5'}/>
                {!!items.length &&
                    <>
                        <span className="sr-only">Notifications</span>
                        <div
                            className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1 dark:border-gray-900"
                        >
                            {get_cart_item_count(cart)}
                        </div>
                    </>
                }
            </button>
        </ShopAccessWrapper>
    )
}
export default CartIconButton
