import React from "react";
import { Button } from "semantic-ui-react";

export function StepsTabsCard({ steps, buttonText, buttonHref }) {
    return (
        <div className="flex flex-col gap-4 md:gap-8">
            <div className={`flex flex-col md:grid md:grid-cols-${steps.length} gap-10 text-white py-6 rounded-lg relative`}>
                {steps.map((step, index) => (
                    <div key={index} className="flex md:flex-col gap-2">
                        <h3 className="text-md md:text-[20px] leading-[28px] font-medium mb-0">{index + 1}.</h3>
                        <div className="text-left">
                            <p className="text-sm md:text-[18px] leading-20px">{step}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-full text-center mt-6">
                <Button
                    href={buttonHref}
                    basic
                    className="w-full md:w-auto text-is2-gray-700 font-medium text-[18px] leading-[24px]"
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}