import {useCallback, useMemo, useState} from "react";
import {useCurrent} from "./useCurrent";
import DefaultSection from "../../views/med/Section";
import {defer} from "../helpers/defer";
import {omit} from "../../helpers/json_functions";

/**
 *
 * @param {UseFormConfig<TFormData>} config
 * @return {UseFormReturn}
 * @template TFormData
 */
export function useForm(config) {
	const initialValues = config.initialValues ?? {};
	const Section = config.Section ?? DefaultSection;

	const [state, setState] = useState(/** @type {FormState} */{
		status: 'idle',
		store: initialValues,
		hasSubmitted: false,
		defaultValues: initialValues,
		valids: {},
		validsErrors: {},
	});

	const ref = useCurrent({state, config});

	const onFormChecked = useCallback((sectionIsValid, sectionValids, sectionErrors) => {
		setState(was => ({
			...was,
			valids: {...was.valids, ...sectionValids},
			validsErrors: {...was.validsErrors, ...sectionErrors},
		}));
	}, []);

	const onChange = useCallback((name, value, values) => {
		// onChange can potentially return an object with additional/altered values to update
		return ref.current.config.onChange?.(name, value, values)
	}, []);

	const onUpdate = useCallback(update => {
		setState(was => ({...was, store: {...was.store, ...update}}));
		// There's a possibility that there's a batched setState that React hasn't processed yet, so the call to
		// `onUpdate` may be stale... but not sure of the best way to handle this (short of a setTimeout(..., 0) to
		// process this on the next tick, or call it from within the setState callback, but both seem wrong).
		const next = {...ref.current.state.store, ...update};
		ref.current.config.onUpdate?.(next);
	}, []);

	const validsErrorsMsg = useMemo(() => {
		return Object
			.keys(state.valids)
			.filter(k => !state.valids[k])
			.map(k => state.validsErrors[k] ?? `Unknown error - ${k}`);
	}, [state.validsErrors, state.valids]);

	const onMountSection = useCallback((section, sectionRef, sectionFields = []) => {
		const unmounting = !sectionRef;

		if (unmounting) {
			if (sectionFields.length) {
				const names = sectionFields.map(field => field.name);

				setState(was => ({
					...was,
					valids: omit(was.valids, names),
					validsErrors: omit(was.validsErrors, names),
				}))
			}
		}
	}, []);

	const onSubmit = useCallback(async (e) => {
		e?.preventDefault();

		const stateAsTimeOfSubmit = ref.current.state;
		const configAsTimeOfSubmit = ref.current.config;
		const {store} = stateAsTimeOfSubmit;

		configAsTimeOfSubmit?.hooks?.validationStarted?.(stateAsTimeOfSubmit);

		setState(was => ({...was, error: null, hasSubmitted: true, result: null}));

		const invalidFields = Object.keys(stateAsTimeOfSubmit.valids).filter(k => stateAsTimeOfSubmit.valids[k] !== true);
		const isValid = invalidFields.length === 0;

		if (isValid) {
			configAsTimeOfSubmit?.hooks?.validationSucceeded?.(stateAsTimeOfSubmit);
			try {
				let reviewed;

				if (configAsTimeOfSubmit.review) {
					const review = defer();

					configAsTimeOfSubmit?.hooks?.reviewStarted?.(stateAsTimeOfSubmit);
					setState(was => ({...was, status: 'reviewing', review: review.resolve}));
					reviewed = await review.promise;
					setState(was => ({...was, review: null, status: reviewed ? was.status : 'idle'}));

					if (!reviewed) {
						configAsTimeOfSubmit?.hooks?.reviewCanceled?.(stateAsTimeOfSubmit);
						return;
					}
					configAsTimeOfSubmit?.hooks?.reviewSucceeded?.(stateAsTimeOfSubmit);
				}

				setState(was => ({...was, status: 'submitting'}));
				configAsTimeOfSubmit?.hooks?.submitStarted?.(stateAsTimeOfSubmit);
				const submission = Promise.resolve(configAsTimeOfSubmit.submit(store, reviewed));

				submission.then(
					result => configAsTimeOfSubmit?.hooks?.submitSucceeded?.(stateAsTimeOfSubmit, result),
					result => configAsTimeOfSubmit?.hooks?.submitFailed?.(stateAsTimeOfSubmit, result),
				);

				const result = await submission;
				setState(was => ({...was, status: 'submitted', result}));

				return result
			} catch (e) {
				const error = e instanceof Error ? e.message : e;
				setState(was => ({...was, status: 'error', error, result: e}));
			}
		} else {
			configAsTimeOfSubmit?.hooks?.validationFailed?.(stateAsTimeOfSubmit);
			setState(was => ({...was, status: 'invalid'}));
		}
	}, []);

	const resetValidation = useCallback(() => {
		setState(was => ({
			...was,
			status: 'idle',
		}));
	}, []);

	return {
		...state,
		validsErrorsMsg,
		showErrors: state.hasSubmitted && state.status === 'invalid',
		visible: 'visible' in config
			? typeof config.visible === 'function'
				? config.visible(state)
				: config.visible
			: true,
		enabled: true,
		// TODO what's the difference between `store` and `formData`
		formData: state.store,
		onSubmit,
		resetValidation,
		onFormChecked,
		onUpdate,
		onChange,
		onMountSection,
		Section,
	}
}

/**
 * @typedef {Object} UseFormConfig<TFormData>
 * @property {Partial<TFormData>} initialValues
 * @property {UseFormSubmitFunction} submit
 * @property {OnChangeHandler<TFormData>=} onChange
 * @property {OnUpdateHandler<TFormData>=} onUpdate
 * @property {boolean=false} review
 * @property {Function|boolean=true} visible
 * @property {ReactNode=} Section
 * @property {FormHooks} hooks
 * @template {Object} TFormData
 */

/**
 * @callback OnChangeHandler<TFormData>
 * @param {string} name
 * @param {unknown} value
 * @param {TFormData} values
 * @return {Partial<TFormData>}
 * @template {Object} TFormData
 */

/**
 * @callback OnUpdateHandler<TFormData>
 * @param {TFormData} values
 * @template {Object} TFormData
 */

/**
 * @typedef {Object} FormHooks
 * @property {Function} validationStarted
 * @property {Function} validationSucceeded
 * @property {Function} validationFailed
 * @property {Function} reviewStarted
 * @property {Function} reviewSucceeded
 * @property {Function} reviewCanceled
 * @property {Function} submitStarted
 * @property {Function} submitSucceeded
 * @property {Function} submitFailed
 */

/**
 * @typedef {Object} FormState
 * @property {FormStatus} status
 * @property {Object} store
 * @property {boolean} hasSubmitted
 * @property {Object} defaultValues
 * @property {unknown} valids
 * @property {unknown} validsErrors
 */

/**
 * @typedef {FormState} UseFormReturn
 * @property {Object} validsErrorsMsg
 * @property {boolean} showErrors
 * @property {boolean} visible
 * @property {boolean} enabled
 * @property {Object} formData
 * @property {FormSubmitFunction} onSubmit
 * @property {Function} resetValidation
 * @property {Function} onFormChecked
 * @property {Function} onUpdate
 * @property {Function} onChange
 * @property {Function} onMountSection
 * @property {ReactNode} Section
 */

/**
 * @callback UseFormSubmitFunction<TFormData, TReviewData = undefined>
 * @param {TFormData} values
 * @param {TReviewData} review
 * @return {Promise<any>}
 * @template {Object} TFormData
 * @template {*} TReviewData
 */

/**
 * @callback FormSubmitFunction
 * @param {Event=} e
 * @return {Promise<any>}
 */

/**
 * @typedef {'idle'|'submitting'|'submitted'|'error'|'invalid'|'reviewing'} FormStatus
 */