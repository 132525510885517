import React from "react";

export default function SingleInfoCard({ icon, headingIntro, title, img, descriptions, postDescription, overlayProfession, overlayName, overlayCompany, overlayLinkUrl, overlayLinkText, isEven }) {

    return (
        <article className={`flex flex-col gap-2 sm:gap-4 ${isEven ? 'sm:flex-row-reverse' : 'sm:flex-row'}`}>
            <div className="relative hidden md:block md:flex-[6] md:basis-6/12 mx-auto">
                <img
                    src={img}
                    alt={title} 
                    className="object-cover w-full h-full hidden md:block rounded-[40px]"
                    style={{
                        aspectRatio: "3 / 4",
                    }}
                    loading="lazy"
                />
                {overlayName && (
                    <div className="absolute bottom-4 left-4 p-4 text-white ">
                        <p className="text-sm text-left">
                            {overlayName} <br />
                            {overlayProfession} <br />
                            {overlayCompany} <br />
                        </p>
                    </div>
                )}
            </div>
            <div className="md:flex-[6] md:basis-6/12 flex flex-col justify-center text-center">
                <span className="text-[18px] leading-[26px] text-[#494E53]">{headingIntro}</span>
                <h2 className="text-[44px] leading-[47px] font-medium text-[#313334] mt-2 mb-9 mx-10 md:mx2">{title}</h2>
                <div className="relative block md:hidden mb-9">
                    <img
                        src={img}
                        alt={title}
                        className="object-cover w-full h-full block md:hidden rounded-[50px] mb-6"
                        style={{
                            aspectRatio: "3 / 3.5",
                        }}
                        loading="lazy"
                    />
                    {overlayName && (
                        <div className="absolute bottom-4 left-4 p-4 text-white rounded-bl-[40px] rounded-br-[40px]">
                            <p className="text-sm text-left">
                                {overlayName} <br />
                                {overlayProfession} <br />
                                {overlayCompany} <br />
                            </p>
                        </div>
                    )}
                </div>

                {icon && <div className="mb-9 mx-auto">
                    <img src={icon} />
                </div>
                }
                {descriptions.map(description => 
                    <p 
                        key={description} 
                        className="text-[#6E7377] mb-2 mx-4 sm:mx-5 text-[18px] leading-[24px]"
                    >
                        {description}  
                    <a
                        href={overlayLinkUrl}
                        className="no-underline text-[#0065F2] hover:text-blue-400"
                    >
                        {overlayLinkText}
                    </a>
                </p>
                )}
                {postDescription && (
                    <span className="text-sm text-gray-500 mt-2">{postDescription}</span>
                )}
            </div>
        </article>
    )
}