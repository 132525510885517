import React, {useContext, useMemo, useState} from 'react';
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import { Loader } from 'semantic-ui-react';
import API_service from '@/xAppLib/providers/API_service';
import { useAsyncUpdate } from '@/xAppLib/Hooks/useAsync';
import { NotificationBar } from '@/views/UIelems/v2/notificationBar/NotificationBar';
import {PARTN} from "@/constants";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {EcommerceDataContext} from "@/xAppLib/context";

import MedicationDrawerComponent from "@/views/ecommerce/components/MedicationDrawerComponent";

function BuyMedicationButton({ item }) {
    const [statusMessage, setStatusMessage] = useState('');
    const navigate = useNavigate();
    const {cart, is_adding_medication, ...actions} = useContext(EcommerceDataContext)

    const {fn: run, loading, error} = useAsyncUpdate(
        async () => API_service.load_data(`ecommerce/getMedicationOptionsFromScript/${PARTN.EVERMED}/${item.sid}`),
        [item]
    );

    const conf = {
        title: 'Select Medication',
        description: 'Please select which medication variant you would like.',
        drawerStyle:'blue'
    }

    const handleBuyClick = async (e) => {
        e.preventDefault();
        setStatusMessage('');

        try {
            actions.switch_on_adding();
            const res = await run();
            const shouldWaitForDismiss = toast.isActive('added-to-cart');
            if(shouldWaitForDismiss){
                toast.dismiss('added-to-cart');

                setTimeout(() => actions.open_drawer({
                    ...conf,
                    content: <MedicationDrawerComponent {...res} />
                }), 500);
            } else {
                actions.open_drawer({
                    ...conf,
                    content: <MedicationDrawerComponent {...res} />
                });
            }
            actions.switch_off_adding();
        } catch (e) {
            actions.switch_off_adding();
            setStatusMessage(e);
        }
    };

    const isInCart = useMemo(() => !!cart?.items?.find(i => i.id === item.epresc__scid), [cart?.items]);

    return (
        <div>
            {isInCart &&
                <div className={'mb-3'} >
                    <NotificationBar type="positive" text={'Medication in cart'} className={"text-center"} />
                </div>
                ||
                <button
                    onClick={handleBuyClick}
                    disabled={loading || is_adding_medication}
                    className="ui button basic w-full flex items-center justify-center gap-2 !mb-2 !py-[6px]"
                >
                    {loading ? (
                        <div className="p-1">
                            <Loader active inline/>
                        </div>
                    ) : (
                        <div className="flex flex-row items-center justify-center">
                            <SIcon name="add-to-cart" size="s" bold cls="text-is2-gray-body"/>
                            <span>Buy medication</span>
                        </div>
                    )}
                </button>
            }

            {statusMessage && (
                <div className="mb-2">
                    <NotificationBar type="warning" text={statusMessage} className={"text-center"} />
                </div>
            )}

            {error && !statusMessage && (
                <div className="text-red-500 my-1 text-sm items-center justify-center text-center">
                    Error checking medication status
                </div>
            )}
        </div>
    );
}

export default BuyMedicationButton;
