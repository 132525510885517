import React, {useContext} from 'react'
import {Button} from "semantic-ui-react";
import {constructMedicationItemForCart} from "@/helpers/evermed";
import {toast} from "react-toastify";
import {EcommerceDataContext} from "@/xAppLib/context";
import MedicationOptionsSelector from "@/views/ecommerce/components/MedicationOptionSelector";
import {useForm} from "@/xAppLib/Hooks/useForm";
import SIcon from "@/views/NUI/StreamlineIcons/SIcon";

// The toast component to be displayed when a medication is added to the cart
export const ToastComponent = ({data, closeToast}) => {
    const handleCheckout = () => {
        // Navigate to the cart page
        // Unfortunately, navigate() is not available in this context or toast provider so we have to do it this way.
        const anchor = document.getElementById('cart-icon-button');
        anchor.click();
        closeToast();
    }

    return (
        <div className="bg-is-green flex flex-col md:flex-row justify-between items-center gap-4 md:gap-8 text-white p-3 px-8">
            <span className={'text-[16px] text-center'}>{data.name} has been added to your bag.</span>
            <div className={'flex gap-3'}>
                <button className={'text-[14px] bg-is-green border-white border rounded-lg py-1 px-2'}
                        onClick={closeToast}>Close
                </button>
                <button className={'bg-white text-[14px] text-gray-700 border-white border rounded-lg py-1 px-2'} onClick={handleCheckout}>
                    <div className={'flex flex-row gap-2 items-center'}>
                        <span>Checkout</span>
                        <SIcon bold svg_cls="h-[18px] w-[18px]" name="delivery-pill"/>
                    </div>
                </button>
            </div>
        </div>
    )
}

// The action buttons for the medication drawer.
// Lives in a seperate component to allow for easy customization
const ActionBtns = (props) => {
    return <div className={'grid grid-cols-2 gap-2 w-full'}>
        <Button onClick={props.onClose} basic>Close</Button>
        <Button color={'blue'} onClick={_ => props.onSubmit()}>Add to Cart</Button>
    </div>
}

// The main component for the medication drawer.
// This component is responsible for adding the selected medication to the cart.
const MedicationDrawerComponent = (data) => {
    const {...actions} = useContext(EcommerceDataContext)

    const handleSubmit = async (values) => {
        try {
            const item = constructMedicationItemForCart({
                medication_data: data.medication_data,
                selected_medication: values.medication
            })
            actions.add_item(item)
            actions.close_drawer();
            toast(ToastComponent, {
                data: {
                    name: values.medication?.label,
                },
                containerId: 'actionable',
                toastId: 'added-to-cart',
                position: 'bottom-center',
                hideProgressBar: true,
                newestOnTop: true,
                draggable: false,
                autoClose:false,
                closeButton: false,
                closeOnClick: false,
                className: '!mb-10 bg-is-green !p-0 !rounded-xl',
                bodyClassName: 'bg-is-green !p-0 !m-0',
            });
        } catch (error) {
            console.log(error)
        }
    }

    const form = useForm({
        submit: handleSubmit,
    })

    return <MedicationOptionsSelector {...{
        ...data,
        ...form,
        actionBtns: ActionBtns
    }} />
}
export default MedicationDrawerComponent
