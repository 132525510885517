import React from "react";
import ServiceCard from "./ServiceCard";
import menu_model from "../../../../models/menu_model";
import { cls } from "../../../NUI/utils";

function ServicesMenu({ onMenuChange }) {

    const services = menu_model.get_servicesmenu()
 
    return (
        <div className="services-menu">
            {services.map((category) => (
                <div key={category.category} className="mt-10 mb-8">
                    <h3 className="text-lg text-center lg:text-left font-medium text-gray-800 my-4">{category.category}</h3>
                    <div className={cls("grid grid-cols-1 xs:grid-cols-2 gap-4", category.category.includes("Specialised") ? "lg:grid-cols-1" : "lg:grid-cols-3 ")}>
                        {category.items.map((item) => (
                            <ServiceCard
                                key={item.title}
                                icon={item.icon}
                                title={item.title}
                                description={item.description}
                                price={item.price}
                                href={item.href}
                                wide={category.category.includes("Specialised")}
                                onMenuChange={onMenuChange}
                            />
                        ))}
                    </div>
                </div>
            ))}
            <span className="text-xs text-gray-600">* after Medicare rebate</span>
        </div>
    );
}

export default ServicesMenu;