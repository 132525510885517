import currency from "currency.js";
import React, { useMemo } from "react";
import SelectableCard from "@/views/UIelems/SelectableCard";
import UniFieldSUI from "@/xAppLib/UniForm/UniField-SUI";
import OOSBadge from "@/views/evermed/OOSBadge";
import {getMedicationPriceByLevel} from "@/helpers/evermed";
import UniFormMed from "@/views/med/UniFormMed";

const MedicationOptionsSelector = ({medication_data, options, actionBtns, ...rest }) => {
    const FIELDS = useMemo(() => [
        {
            name: 'medication',
            label: 'Medication Selection',
            type: 'object',
            validate: (v) => v.trim().length === 18,
        },
        medication_data?.has_dva_white && {
            name: 'dva_eligible',
            label: 'Are you eligible to receive concession pricing for this medication under your DVA policy?',
            type: 'bool',
        },
    ].filter(Boolean), [JSON.stringify(medication_data)]);

    const getPricingLevel = (values, opt) => medication_data.is_private_prescription && 'original'
        || opt.prices.concession && medication_data.has_valid_concession
        || (medication_data.has_valid_dva && (
                !medication_data.has_dva_white
                || medication_data.has_dva_white && values.dva_eligible === true
            )
        ) && 'concession'
        || opt.prices.general && medication_data.has_valid_medicare && 'general'
        || 'original';

    const ActionSection = actionBtns

    return (
        <UniFormMed fields={FIELDS} {...rest}>
            {(values, valids, uf_this, fields) => {
                return (
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col gap-2 items-center w-full">
                            {options.map((opt) => {
                                const pricingLevel = getPricingLevel(values, opt);
                                const outOfStock = opt.stock_status === 'out_of_stock';
                                const price = getMedicationPriceByLevel(pricingLevel, opt.prices);
                                const value = {
                                    ...opt,
                                    config: {
                                        charged_pricing_level: pricingLevel,
                                        charged_price: price
                                    }
                                }
                                return (
                                    <SelectableCard
                                        key={opt.productVariantType}
                                        isInvalid={valids?.medication === false}
                                        isSelected={values.medication?.id === opt.id}
                                        onSelect={() => uf_this.handleInputChange(null, {
                                            name: "medication",
                                            value
                                        })}
                                        isDisabled={outOfStock}
                                        className={'w-full'}
                                    >
                                        <div className={'w-full flex justify-between p-1 text-gray-800'}>
                                            <span>{opt.label}</span>
                                            <span>{outOfStock ? <OOSBadge/> : currency(price).format()}</span>
                                        </div>
                                    </SelectableCard>
                                );
                            })}
                            {fields.dva_eligible && (
                                <div className={'p-4 text-sm !items-center'}>
                                    <UniFieldSUI fl={fields.dva_eligible} {...{valids, values, uf_this}}/>
                                </div>
                            )}

                            {actionBtns && <ActionSection {...rest}/>}
                        </div>
                    </div>
                );
            }}
        </UniFormMed>
    );
}

export default MedicationOptionsSelector;