import React, { useState, useEffect } from 'react';
import { Header } from './Header';
import { Button } from 'semantic-ui-react';
import { cls }  from "../../../NUI/utils";
import { Divider } from '../Divider';
import WaitTimeConsultBook from '../../../public/waittime/components/consult_book';
import { Link } from 'react-router-dom';
import Welcome from './Welcome';
import { NotificationsBanner } from '../NotificationsBanner';
import UserPic from '../../../../xAppLib/Users/UserPic';
import { ActiveConsult } from './ActiveConsult';

export function HeaderHome({user=false}) {

    const img_path = "/images/v2/home"
    const user_in = user?.user_in

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {setScrollY(window.scrollY)};
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    const hearts = <>
                        <div className="flex justify-between align-center w-full absolute bottom-0 left-0 right-0 mx-auto max-w-[300px]">
                            <img className="w-[80px] sm:w-[108px] h-[60px] -ml-6 object-cover object-top xl:hidden" src={`${img_path}/hearts-grouped.png`} />
                            <img className="w-[196px] h-auto absolute left-0 right-0 mx-auto -bottom-[68px] rotate-[3.043deg] xl:hidden z-10" src={`${img_path}/heart-single-large.png`} />
                            <img className="w-[80px] sm:w-[108px] h-[60px] -mr-9 object-cover object-top xl:hidden" src={`${img_path}/hearts-grouped2.png`}/>
                        </div>
                        
                        <img style={{ transform: `translateY(${scrollY * -0.1}px)` }} className="h-[660px] absolute left-0 top-0 bottom-0 hidden xl:block" src={`${img_path}/hearts-grouped-large-2.png`} />
                        <img style={{ transform: `translateY(${scrollY * -0.2}px)` }} className="absolute left-[320px] bottom-[60px] hidden xl:block" src={`${img_path}/hearts-3.png`} />
                        <img style={{ transform: `translateY(${scrollY * -0.3}px)` }} className="absolute w-[140px] left-[370px] bottom-[100px] hidden xl:block" src={`${img_path}/med-heart-1.png`} />
                    </>

    return (
        <Header
            sub_title={!user_in && "Doctors here for you 24/7"}
            title={!user_in && <>Australia’s largest <br/>online medical clinic.</>}
            nav={user_in}
            user={user_in}
            sidebar={user_in}
            current={'Home'}
            wrapper={cls("h-full !bg-is2-blue", !user_in && "xl:h-[660px] ")}
            clsn="home text-center"
            padding={cls("xl:max-w-[533px]", user_in ? "w-full px-6 py-9 lg:pb-6 lg:pt-11 mx-auto" : "pt-11 px-6 pb-10 xl:pb-0 md:pt-[100px] xl:pt-[180px] xl:h-[743px] xl:!max-w-full mx-auto xl:ml-[50vw] xl:h-[600px]")}
        >
            <>
                <ActiveConsult limit={1} clsn="order-1 lg:hidden -mt-9 !-mx-6 mb-3"/>

                {user_in && <div className="order-3 lg:order-none flex flex-col justify-center items-center lg:items-start">
                                <div className="!border !border-white !border-2 !rounded-full !w-[76px] !h-[76px] flex items-center justify-center mb-6 lg:mb-3">
                                   <UserPic
                                       photoURL={user?.user_det?.photoURL}
                                       email={user?.user_det?.email}
                                       name={user?.user_det?.displayName}
                                       size={72}
                                       className="!w-[72px] !h-[72px] !rounded-full !bg-cover !bg-no-repeat"
                                   />
                                </div>
                                <Welcome clsn="lg:hidden"/>

                                {user_in &&<p className="hidden lg:block text-white font-bold">{user.prof.first_name} {user.prof.last_name}</p>}
                                <Divider color="blue" clsn="lg:hidden"/>
                                <NotificationsBanner clsn="lg:hidden"/>
                            </div>}

                <div className={cls(user_in ? "mb-9 lg:hidden order-2" : "my-9 xl:my-[60px] w-fit mx-auto xl:mx-0" )}><WaitTimeConsultBook basic badge /></div>

                <div className={cls("w-full flex flex-col items-center mx-auto", user_in ? "lg:gap-3 lg:mt-12 lg:hidden order-4" : "mb-[124px] md:w-[327px] lg:mt-[36px] xxl:mt-[60px] xl:ml-0 xl:hidden")}>
                    <Button data-testid="button-services" className={cls("!py-2.5 rounded !text-[20px] !leading-[30px] !font-medium white !w-full !md:w-[327px]")} onClick={() => app.trigger(app.events.SIDEMENU, {view:'Services'})} size="large">Select service</Button>
                    <p className={cls(user_in && "xl:hidden", "my-2")}>or</p>
                    <Button basic color="blue" className="w-full sm:w-fit" as={Link}>
                        <span className="font-normal text-[#CCE0FC]" onClick={() => app.trigger(app.events.SIDEMENU, {view:'Conditions'})} >Browse by <span className="font-bold text-white">medical conditions</span></span>
                    </Button>
                </div>

                {!user_in && <div className="hidden xl:flex gap-4">
                    <Button onClick={() => app.trigger(app.events.REQUEST_JOIN)} className="rounded white large">I'm new</Button>
                    <Button onClick={() => app.trigger(app.events.REQUEST_LOGIN)} className="rounded dark-blue large">Log in</Button>
                    </div>}

                {!user_in && hearts}

            </>
        </Header>
    );
}

