import React, { useState, useEffect, useMemo } from "react";
import {
	Button,
	Container,
	Loader
} from "semantic-ui-react";
import { Link, useSearchParams } from "react-router-dom";
import { useProfile, useUser } from "xAppLib/Hooks";
import gtm from "../../xAppLib/providers/gtm";
import { cls } from 'views/NUI/utils';
import moment from "moment";
import user_model from "../../models/user_model";
import environment from "../../conf/environment";
import InfoHomeCard from "../UIelems/v2/InfoHomeCards/InfoHomeCard";
import SingleInfoCard from "../UIelems/v2/InfoHomeCards/SingleInfoCard";
import { FooterDownload } from "../UIelems/v2/Footer";
import { SpeakToADoctorCopy, PrescriptionsCopy, MedicalCerfiticateCopy, PathologyCopy, SpecialistCopy } from "../UIelems/v2/stepsTabs/StepsTabsContent";
import StepsTabs from "../UIelems/v2/stepsTabs/StepsTabs";
import ConditionCardCarousel from "../UIelems/v2/conditionsCards/ConditionCardCarousel";
import ConditionCardGrid from "../UIelems/v2/conditionsCards/ConditionCardGrid";
import { HeaderHome } from "../UIelems/v2/Header/HeaderHome";
import FaqSection from "../UIelems/v2/FaqSection";
import { discount as refer_discount } from "./Refer";
import { TextImageBox } from "../UIelems/v2/TextImageBox";
import { HealthMonitor } from "../UIelems/v2/HealthMonitor";
import SIcon from "../NUI/StreamlineIcons/SIcon";
import Welcome from "../UIelems/v2/Header/Welcome";
import scripts_list_model from "../../models/scripts_list_model";
import { NotificationsBanner } from "../UIelems/v2/NotificationsBanner";
import ActivePrescriptionsDashboard from "../../components/medications/ActivePrescriptionsDashboard";
import { LastOrder } from "../UIelems/v2/LastOrder";
import AccountOnboarding from "../../xAppLib/UIelems/AccountOnboarding";
import MFA from "../../xAppLib/UIelems/MFA";
import { ActiveConsult } from "../UIelems/v2/Header/ActiveConsult";

const img_path = environment.storage.instantMedPublic.is2;

const articles = [
	{
		id: 'refer',
		title: "Refer a friend",
		desc: <>Refer a friend to InstantScripts and you will both receive ${refer_discount} off your next order.</>,
		img: "refer-a-friend-home.jpg",
		link: "/refer-a-friend",
		secondaryBtn:"Maybe later",
		primaryBtn: <>Get ${refer_discount} free</>
	},
	{
		id: 'onepass',
		title: "Activate your OnePass membership",
		desc: "Manage your weight with our medically approved and doctor recommended treatments.",
		content:<div className="bg-op-purple flex items-center justify-center w-full h-[200px] rounded-[28px]">
			<img src="https://storage.googleapis.com/instant-med-public/OnePass/onepass_purple_bg.svg" />
		</div>,
		link: "/onepass",
		primaryBtn: "Activate now",
		secondaryBtn: "Maybe later"
	},
	{
		id: 'consults',
		title: `General telehealth consultations now $49`,
		desc: "We’ve dropped the price of telehealth to make it even more convenient for you to get the healthcare you need. Plus, we now offer repeats for selected medications. Speak to a doctor 24/7.",
		img: "consults-home.jpg",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation",
	},
	{
		id: 'certs',
		title: `Two-day medical certificates for $19`,
		desc: "If you’re not feeling well and need time off work, you can now request a medical certificate for up to two days for just $19. All requests are reviewed by our doctors.",
		img: "medcert-home.jpg",
		link: "/c/pharm/certs",
		primaryBtn: "Request a medical certificate"
	},
	{
		id: 'delivery',
		title: "We offer same day delivery!",
		desc: "We’ve partnered with Evermed and DoorDash to offer same day delivery so you don’t have to leave your house to get your medication. *Small fee applies and subject to availability in your area.",
		img: "delivery-home.jpg",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation"
	},
	{
		id: 'patho',
		title: "Get a pathology referral",
		desc: "Did you know we offer a variety of blood tests? Request a blood test, take the form to your local collection centre and the results will be sent to you in a few days.",
		img: "patho-referral-home.jpg",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation"
	},
	{
		id: 'sisterhood',
		title: "We’re giving back to charity",
		desc: "InstantScripts has joined the Sisterhood foundation helping a variety of charities across Australia deliver programs that create positive health outcomes in the lives of women and girls.",
		img: "sisterhood-foundation.png",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation"
	},
	{
		id: 'bookings',
		title: "We now offer a booking service!",
		desc: "Want to speak to a doctor but need some privacy before your call? No worries. Just select a time that's convenient for you.",
		img: "booking-home.png",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation",
	},
]

const faq_data = [
	{
		title: 'Can I receive a Medicare rebate?',
		content: <>
			<p>The Australian Government does not permit Medicare rebates for any telehealth provider where a face-to-face consultation has not previously occurred in the last 12 months. This means you cannot claim your InstantScripts consultation with Medicare.</p>
			<p>InstantScripts offers highly competitive flat-fee pricing for medical services that is often cheaper than out-of-pocket expense on Medicare-funded services.</p>
		</>
	},
	{
		title: 'Who supplies the medication?',
		content: <>
			<p>If you have been prescribed medication you will receive an eScript that can be brought to any pharmacy of your choice.</p>
			<p>If you would like to have your medication delivered to you, InstantScripts has partnered with EverMed for our medication deliveries.</p>
		</>
	},
	{
		title: 'Where are InstantScripts doctors?',
		content: <>
			<p>Every InstantScripts doctor is an AHPRA-registered and fully-insured doctor based in Australia.</p>
			<p>Every doctor must adhere to the highest clinical standards and your doctor’s prescriber number will be written on any prescription that is prescribed.</p>
		</>
	}
]

const HeaderHomeWrapper = ({user}) => {
	return (
		<section className={cls(!user?.user_in && "xl:bg-is2-blue w-full h-full")}>
			<HeaderHome user={user} />
		</section>
	);
}

const ReviewRating = ({clsn}) => {
	const total = 4.7;
	const stars_empty = 5 - Math.ceil(total);
	const perc = Math.round((total - parseInt(total)) * 100);

	return (
		<div className={cls("bg-white", clsn )}>
			<svg className="w-0 h-0" viewBox="0 0 24 24"><defs>
				<mask id="perc">
					<rect x="0" y="0" className="h-full w-full fill-white" />
					<rect x={String(perc) + '%'} y="0" className="h-full w-full fill-[#3d3d3d]" />
				</mask>
				<symbol className="h-fit" xmlns="http://www.w3.org/2000/svg" id="star">
					<path d="M12.5212 0.447798L15.8362 7.07613L22.2129 7.71446C22.552 7.74763 22.7789 7.92463 22.8937 8.24538C23.0085 8.56613 22.9455 8.84696 22.7045 9.0878L17.4495 14.3411L19.3979 21.4795C19.4889 21.8159 19.3997 22.0945 19.1302 22.3155C18.8607 22.5365 18.57 22.5695 18.2579 22.4145L11.7995 19.1845L5.3412 22.4178C5.02912 22.5736 4.73837 22.5409 4.46879 22.3195C4.19929 22.098 4.11062 21.8192 4.20287 21.4828L6.14954 14.3411L0.894537 9.08446C0.653621 8.84363 0.590538 8.5628 0.705371 8.24205C0.820204 7.9213 1.04712 7.7443 1.3862 7.71113L7.76287 7.0728L11.0779 0.444464C11.2271 0.147297 11.468 -0.000869237 11.8005 -0.000119237C12.133 0.000630763 12.3733 0.149964 12.5212 0.447798Z" />
				</symbol>
			</defs>
			</svg>

			<Container>
				<p className="font-normal text-center text-is2-body !mb-1">We’re rated on average {total}/5</p>
				<div className="flex flex-col items-center justify-center gap-1 pb-3 sm:pb-2">
					<div className="flex mt-2">
						{Array(Math.floor(total)).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is2-yellow-500"></use></svg>)}
						{perc ? <svg className="h-6 w-6"><use href="#star" mask="url(#perc)" className="fill-is2-yellow-500"></use></svg> : null}
						{Array(stars_empty).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is2-yellow-500"></use></svg>)}
					</div>
				</div>
			</Container>
		</div>
	)
}

const ConditionCardWrapper = () => {
	const path = '/images/v2/speak-to-doc-img/'
	const cardsData = [
		{
			title: 'Anxiety and depression',
			imageUrl: `${path}mental-health-hero.jpg`,
			url: '/c/pharm/-LMrOQSVQMBVgUqe3y2A'
		},
		{
			title: 'Cold and flu',
			imageUrl: `${path}cold-and-flu-hero.jpg`,
			url: '/c/pharm/-N9YuK8ZZsDznRtdISNF'
		},
		{
			title: 'Muscle pain',
			imageUrl: `${path}pain-or-illness-hero.jpg`,
			url: '/c/pharm/-LJn8ZK_ufxt8fWpDyl8'
		},
		{
			title: 'Weight loss',
			imageUrl: `${path}weight-loss-hero.jpg`,
			url: '/c/pharm/-N1fTGgW2ONhP0Zurrnn'
		},
		{
			title: 'Asthma',
			imageUrl: `${path}asthma-hero.jpg`,
			url: '/c/pharm/-LJVyR5Pdls_ydzW8sRk'
		},
		{
			title: 'Blood pressure',
			imageUrl: `${path}blood-pressure-hero.jpg`,
			url: '/c/pharm/-LJlvQo2RunU9oi9abk5'
		},
		{
			title: 'Sexual health',
			imageUrl: `${path}sexual-health-hero.jpg`,
			url: '/c/pharm/erectile-dysfunction'
		},
		{
			title: 'Skin treatments',
			imageUrl: `${path}skin-conditions-hero.jpg`,
			url: '/c/pharm/-LJVyeBvRW_BnUCsx0kw'
		},
		{
			title: 'Quitting nicotine',
			imageUrl: `${path}quitting-nicotine-hero.jpg`,
			url: '/c/pharm/-M2kr-X-SGFjWTib-pYM'
		},
		{
			title: 'Alternative therapy',
			imageUrl: `${path}alternative-therapy-hero.jpg`,
			url: '/m/pharm/subscr/DocConsICann'
		}
	];

	const searchIcon = "https://storage.googleapis.com/instant-med-public/v2/search-blue.svg"

	return (
		<section className="w-full px-6 py-12 bg-white">
			<div className="w-full lg:max-w-[1124px] sm:px-4 mx-auto">
				<h3 className="font-medium text-center text-is2-gray-800 text-[48px] leading-[47px] mt-1 sm:mt-2 mb-[52px] ">Speak to our doctors about:</h3>
				<ConditionCardGrid items={cardsData} />
				<ConditionCardCarousel items={cardsData} />
				<div className="flex flex-col lg:flex-row items-center justify-center gap-4 my-10">
				<Button as={Link} to="/c/pharm/DocCons" basic className="!pl-4 !py-3">
						<div className="flex items-center">
							<SIcon name="doc-steth" cls="text-is2-blue h-[30px] w-[30px]" bold />
							<p className="ml-2">Speak to a doctor</p>
						</div>
					</Button>
					<p className="mb-0">or</p> 
					
					<Button href="#" onClick={() => app.trigger(app.events.SIDEMENU, {view:'Conditions'})} basic className="!pl-4 !py-3">
						<div className="flex items-center">
							<img
								src={searchIcon}
								alt="Search Icon"
								className="h-[30px]"
								aria-hidden="true"
							/>
							<p className="ml-2">Find your condition</p>
						</div>
					</Button>
				</div>
			</div>
		</section>
	);
}

const InfoHomeCardsWrapper = () => {

	const infoHomeItems = [
		{
			icon: `${img_path}/heart-golden-icon.svg`,
			headingIntro: "Stay home and relax",
			title: "Healthcare at home.",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-1.png",
			descriptions: [
				"Don't leave home, drive, or be around sick people in a waiting room.",
			],
			postDescription: "",
		},
		{
			icon: "https://storage.googleapis.com/instant-med-public/v2/cross-golden-icon.svg",
			headingIntro: "We deliver in under 4 hours",
			title: "Fast medication delivery",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-2.png",
			descriptions: [
				`If you want medication fast, we can deliver it to you in <span class="underline underline-offset-2 decoration-wavy decoration-[#FBDDB3] font-bold">under 4 hours*</span>.`,
				"eScripts and Click & Collect also available. *4-hour delivery only available to metro locations.",
			],
			postDescription: "*3-hour delivery only available to metro locations.",
		},
		{
			icon: "https://storage.googleapis.com/instant-med-public/v2/cross-golden-icon.svg",
			headingIntro: "Night doctors available",
			title: "Open 24/7",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-24-7.jpg",
			descriptions: [
				"Don't wait till the morning. Our doctors are here to help at any time.",
			],
			postDescription: "",
		},
	]

	const singleInfoItems = [
		{
			headingIntro: "Over 150 doctors",
			title: <>Real doctors,<br/> real care.</>,
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-4.png",
			icon: "https://storage.googleapis.com/instant-med-public/v2/ahpra-nations-boards-icon.png",
			postDescription: "",
			overlayText: "Dr. Sarah, Chief Medical Officer",
			overlayProfession: "Chief Medical Officer",
			overlayName: "Dr. Sarah",
			overlayCompany: "InstantScripts",
			descriptions: [
				"Every InstantScript doctor is Australian based and registered with the  ",
			],
			overlayLinkText: "Australian Health Practitioner Regulation Agency.",
			overlayLinkUrl: "https://example.com/learn-more"
		},
	]

	return (
		<>
			<section className="grid grid-cols-1 py-20 lg:py-[220px] sm:px-10 bg-is2-yellow-100">
				<div className="w-full lg:max-w-[1124px] px-4 mx-auto">
					<img src={`${img_path}/heart-golden-icon.svg`} className="mx-auto"/>
					<h2 className="text-[44px] lg:text-[64px] leading-[47px] font-medium text-center text-is2-gray-800 mt-[60px] mb-[180px]">We make healthcare convenient...</h2>
					{infoHomeItems.map((item, i) => {
						return (
							<InfoHomeCard key={i} {...item} isEven={Boolean(i % 2 !== 0)} />
						)
					})}
				</div>
			</section>
			<section className="grid grid-cols-1 py-[120px] sm:px-10 bg-white">
				<div className="lg:max-w-[1124px] px-4 mx-auto">
					{singleInfoItems.map((item, i) => {
						return (
							<SingleInfoCard key={i} {...item} isEven={Boolean(i % 2 !== 0)} />
						)
					})}
				</div>
			</section>
		</>
	);
}

const StepsTabsWrapper = () => {
	const elements = [
		{
			name: "Speak to a doctor",
			id: "doctor",
			content: <SpeakToADoctorCopy />,
		},
		{
			name: "Prescriptions",
			id: "prescriptions",
			content: <PrescriptionsCopy />,
		},
		{
			name: "Medical certificates",
			id: "certificates",
			content: <MedicalCerfiticateCopy />,
		},
		{
			name: "Pathology",
			id: "pathology",
			content: <PathologyCopy />,
		},
		{
			name: "Specialist referrals",
			id: "referrals",
			content: <SpecialistCopy />,
		},
	];

	return (
		<div className="w-full px-6 py-28 bg-is2-yellow-100">
			<div id="steps-tabs-wrapper" className="w-full lg:max-w-[1124px] mx-auto">
				<p className="font-normal text-center text-is2-gray-350 mb-1 text-[18px] leading-[26px]">Fast and easy</p>
				<h2 className="font-medium text-center text-is2-gray-800 text-[44px] leading-[47px] pb-4 sm:pb-6">How it Works</h2>
				<StepsTabs elements={elements} />
			</div>
		</div>
	)
}

const PrescriptionsWrapper = () => {
	return (
		<div className="px-6 py-9 rounded bg-white hidden lg:block">
			
		</div>
	)
}

const YourHealthWrapper = () => {
	return (
		<div className="hidden lg:block">
			<TextImageBox 
				title="Understand your health" 
				desc="Understand and monitor how healthy you are. Take our free health quiz to setup your health profile." 
				content={<HealthMonitor />}
				btnText="Get Started" />
		</div>
	)
}


const BannersWrapper = ({prof, layout, title, clsn}) => {

	const expiry =  1000 * 60 * 60 * 24;
	const [dismissed, setDismissed] = useState([]);

	const bannersList = useMemo(() => {
		const articlesToShow = (
			layout === "main" && ['consults', 'certs', 'delivery', 'patho', 'sisterhood', 'bookings']
			|| layout === "side" && ['refer'].concat(...(!prof?.onepass ? ['onepass'] : []))
		);

		const isDismissed = (id) => dismissed.includes(id) || JSON.parse(sessionStorage.getItem(`${id}_home_dismissed`))?.expiry > new Date().getTime();

		return articles.filter(article => articlesToShow.includes(article.id) && !isDismissed(article.id))
	}, [dismissed]);

	const hideBanners = (id) => {
		setDismissed([...dismissed, id]);
		sessionStorage.setItem(
			`${id}_home_dismissed`,
			JSON.stringify({ expiry: new Date().getTime() + expiry })
		);
	}

	return (<div className={cls(clsn, layout != "side" && "bg-white px-6 pt-9 rounded")}>
				{title && <h3 className="mb-4 font-medium text-[20px] leading-[28px] hidden lg:block">{title}</h3>}
				<div className="flex flex-wrap gap-2 lg:gap-6">
					{bannersList.map((article, i) => {
						return (
							<TextImageBox key={i} {...article} col={layout==="side"} onDismiss={(id)=> hideBanners(id)} />
						)
					})}
				</div>
			</div>
	)
}

const Partners = () => {

	const partners_list =
		[
			{
				name: 'NSW Health',
				img: img_path+'/partners/NSW-health.svg',
				link: 'https://www.health.nsw.gov.au/'
			},
			{
				name: 'Priceline',
				img: img_path+'/partners/Priceline-pharmacy.svg',
				link: 'https://www.priceline.com.au/'
			},
			{
				name: 'Onepass',
				img: img_path+'/partners/Onepass.svg',
				link: 'https://onepass.com.au/'
			},
			{
				name: 'Doordash',
				img: img_path+'/partners/Doordash.svg',
				link: 'https://www.doordash.com/'
			},
		]
	return <div className="bg-white flex flex-col items-center px-6 py-[60px] lg:py-[120px] w-full">
				<h2 className="text-center font-medium text-is2-gray-300 text-xl md:text-[44px] leading-[40px] mb-8">Join over 2 million InstantScripts users</h2>
				<ReviewRating />

				<Button primary blue className="!my-[52px]" onClick={() => app.trigger(app.events['REQUEST_JOIN'])}>Create your account</Button>
				
				<p className="text-center text-is2-body mb-7 mt-0">We’re partnered with:</p>
				<div className="flex flex-wrap space-between items-center w-full md:justify-center md:space-x-12">
					{partners_list.map((el, i) => <a href={el.link} className="w-1/2 md:w-fit mb-7 md:mb-0" target="_blank" key={i}><img className="w-[100px] md:w-[140px] mx-auto" src={el.img} /></a> )}
				</div>
			</div>
}


const Help = () => {
	return <div className="bg-is2-yellow-100 pt-14 pb-12 md:py-9 px-6 lg:rounded-[28px]">
				<h3 className="text-left lg:text-center mb-6 font-medium text-[20px] leading-[28px]">Need some help?</h3>
				<div className="flex flex-col md:flex-row gap-4 items-center md:justify-center">
					<Button basic className="!w-full" as={"a"} href="https://help.instantscripts.com.au/en/">View our help articles</Button>
					<p className="mb-0 text-sm md:hidden">or</p>
					<Button basic className="!w-full" onClick={()=>window.Intercom('show')}>Chat to us</Button>
				</div>
			</div>
}

const AccountOnboardingWrapper = () => {
	return  <div className="hidden lg:block">
				<AccountOnboarding />
				<MFA />
			</div>
}

const FooterWrapper = ({clsn, bg}) => {
	return (
		<div className={clsn||''}>
			<FooterDownload bg={bg} />
		</div>
	)
}

const HomepagePatient = ({ publicView = false, about = false }) => {

	const user = useUser()
	const [searchParams] = useSearchParams();
	const [prof] = useProfile()
	const [scrollY, setScrollY] = useState(0);
	const newUser = !prof?.hist.length;
	const user_in = user.user_in

	useEffect(() => {
		searchParams.get('oidc') && user_model.onepass_login(true);
	}, []);

	if (app.settings.is_ionic && !app.state.loaded) {
		return <Loader active />
	}

    useEffect(() => {
        const handleScroll = () => {setScrollY(window.scrollY)};
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

	return <div className={cls("flex flex-col", user_in ? "lg:flex-row" : "xl:bg-is2-blue")}>
		{/* {!__IONIC__ && <Hero user_in={user_in && !publicView} about={about} />} */}

		<HeaderHomeWrapper user={user} />

		{user_in ? 

			<div className="lg:p-4 flex justify-center gap-4 w-full">
				<div className="lg:mx-auto lg:py-12 flex flex-col gap-3 lg:px-0 lg:max-w-3/4 xl:max-w-[737px]">

				<Welcome clsn="hidden lg:block" />

				{newUser && <AccountOnboardingWrapper />}

				<NotificationsBanner clsn="hidden lg:flex" />

				<ActiveConsult clsn="block" />

				<LastOrder clsn="block lg:hidden bg-white px-6 py-9" />

				{!newUser && <div className="h-fit block md:hidden"><BannersWrapper layout="side"/></div>}

				<LastOrder clsn="lg:block hidden" />

				{!newUser && <ActivePrescriptionsDashboard cardQty={2} />}

				<BannersWrapper layout="main" title="What's new"/>
				
				<FooterWrapper bg="bg-is2-blue-50" clsn="lg:hidden"/> 

				<Help />

				</div>
				
				{!newUser && <div className="h-fit xl:max-w-[363px] hidden menu-xl:block"><BannersWrapper layout="side"/></div>}
				
			</div>
		
		:

			<div className="home-main xl:z-[12] bg-white relative">

				<img style={{ transform: `translateY(${scrollY * -0.2}px)` }} className="w-[268px] h-auto absolute left-[400px] mx-auto -top-[180px] hidden xl:block rotate[-167.32deg]" src={`/images/v2/home/large-heart-1.png`} />
                 
				<ReviewRating clsn="pt-10 sm:pt-16 sm:pb-0" />

				<ConditionCardWrapper />

				<InfoHomeCardsWrapper />

				<StepsTabsWrapper />

				<FaqSection data={faq_data}  title={<>Frequently<br/> asked questions</>}/>
				
				<FooterWrapper />

				<Partners />
				
			</div>
		}

	</div>
};

export default HomepagePatient;
