import React from 'react';
import { useProfile } from '../../../xAppLib/Hooks';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { cls } from '../../NUI/utils';

export function NotificationsBanner({clsn}){

	const [prof] = useProfile();
	const notif = 4; //placeholder

	return (
			prof?.hist?.length && notif>0 ?
			<div className={cls("w-fit flex items-center justify-between bg-is2-yellow-100 lg:bg-is2-green py-3 px-4 gap-3 lg:gap-[32px] rounded-[4px] lg:rounded-[8px] mb-9", clsn)}>
				<p className="!mb-0 font-normal text-left lg:text-center !text-is2-gray-700">You have {notif} new notifications <span className="text-sm">(placeholder)</span></p>
				<Button as={Link} to="/" className="ui button basic blue !bg-white hover:lg:!bg-is2-blue-50 !text-is2-gray-700 hover:lg:!outline-is2-gray-100 lg:!outline-is2-gray-100">View</Button>
			</div>
			: null
	);
}

