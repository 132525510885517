import React, {useReducer,useEffect} from 'react'
import { Link } from 'react-router-dom'

import { 
		Container,
		Grid,
		List,
		Icon,
	} from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import PrepFooter from 'views/iprep/Footer'
import IcannFooter from 'views/icann/Footer'
import IlegFooter from 'views/ileg/Footer'
import IcosmFooter from 'views/icosm/Footer'
import DeviceOrgSelector from '../../views/icosm/DeviceOrgSelector'
import { DebugExperiments } from "./DebugExperiments";
import Experiment from "../AB/Experiment";
import { SiteVariationSelector } from "./SiteVariationSelector";


const company = [
	{
	  name: "About Us",
	  link: "/about-us/",
	},
	{
	  name: "Careers",
	  link: "https://careers.smartrecruiters.com/InstantScripts",
	},
	{
	  name: "Contact Us",
	  link: "#",
	},
	/*{
		name: "News",
		link: "https://www.instantscripts.com.au/blog/",
	},*/
];
const patients = [
	{
		name: "Online prescriptions",
		link: "/online-scripts/",
	},
	{
		name: "Doctor consultations",
		link: "/telehealth-consultations/",
	},
	{
		name: "Treatment plans",
		link: "/treatment-plans/",
	},
	{
		name: "Blood test requests",
		link: "/online-pathology-requests/",
	},
	{
		name: "Medical certificates",
		link: "/online-medical-certificates/",
	},
	{
		name: "Specialist referrals",
		link: "/online-specialist-referrals/",
	},
	{
		name: "Price guide",
		link: "/pricing/",
	},
	{
		name: "Refer a friend",
		link: "/refer-a-friend/",
	},
]
const staff = [

	{
		name: "For Doctors",
		link: "https://careers.smartrecruiters.com/InstantScripts",
	},
	{
		name: "For Pharmacies",
		link: "/pharmacy-registration/",
	},
	{
		name: "For Cosmetic Clinics",
		link: "https://instantcosmetics.com.au/",
	},
]

export default function Footer() {
	const forceUpdate = useReducer(x => x + 1, 0)[1];
	const location = useLocation()

	const curr_page = location.pathname

	useEffect(() => {
		app.on(app.events.SITE_READY, forceUpdate)
		return () => {
			app.off(app.events.SITE_READY, forceUpdate);
		};
	}, []);

	
		if (app.settings.is_bare)
			return null
		
		if (app.settings.iprep)
			return <PrepFooter curr_page={curr_page}/>

		if (app.settings.icann)
			return <IcannFooter />

	    if (app.settings.ileg)
			return <IlegFooter />
		if (app.settings.icosm)
			return <IcosmFooter />
	
		const links = app.app_data.ws_conf?.footer.items.map(it=>it.popup&&app.app_data.ws_conf.popups[it.popup]||it).map(it=>(!it.target || it.target.includes(app.runtime.app))&&it).filter(Boolean)
		return <div className='ui inverted vertical segment' id="site-footer">
		{(app.settings.is_term || app.settings.is_ionic) &&
			<Container >
				<Grid divided={false} inverted stackable>
					<Grid.Row>
						<Grid.Column width={12} >
							<List horizontal link inverted >
								{
									links && links.map( (it, i) => <List.Item
												content={app.settings.is_mob_sz && it.txt_shrt || it.txt}
												name={app.settings.is_mob_sz && it.txt_shrt || it.txt}
												as={it.tp == 'ln' ? Link : 'div'}
												to={it.u} 
												active={curr_page === it.u} 
												onClick={()=>it.tp=='pu' && app.trigger(app.events.SHOW_POPUP, it)}
												style={{cursor:'pointer'}}
												key={'foot_menu_more'+i}
											/>
										)
								}

								{app.runtime.app =='icosm' && app.user?.claims?.nurse && <a href="tel:0490064850">Contact Us</a>}
							</List>
						</Grid.Column>
						
						<Grid.Column width={8} verticalAlign='bottom'>
							<p className="text-center">	© {new Date().getFullYear()} {app.runtime.name}</p>
						</Grid.Column>
						<Grid.Column width={8} className='version'>
							<DeviceOrgSelector />
							{app.runtime.org?.oid!='app'&&<i>{app.runtime.org?.name}<br/></i> || null}
							Build: {__GIT_HASH__} - {__BUILD__}
							{app.dev_env &&  ' - dev' || app.test_env &&  ' - test' || ''}
							&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
							<Icon
									compact="true"
									color="green"
									name="refresh"
									circular
									onClick={_=>window.location.reload(true)}
									style={{ cursor: 'pointer' }}
								  />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		||
			<Container>
			  <div className="relative pt-8 pb-4 md:py-12 flex flex-wrap  w-full justify-between items-start gap-4 md:gap-10 lg:gap-20 text-left">
				<img src="/images/logos/instant-scripts-logo-white2.svg" alt="InstantScripts" className="logo mb-4 h-20 order-1"/>
				
				<div className="order-last md:order-2 flex md:flex-col xl:flex-row flex-wrap justify-between lg:flex-1 relative md:absolute left-0 md:top-[150px] xl:top-0 xl:relative gap-4 md:w-[154px] xl:w-auto md:items-center mt-4 md:mt-0">	
					<a href="https://www.legitscript.com/websites/?checker_keywords=instantscripts.com.au" target="_blank" className="h-fit shrink-0"><img src="https://static.legitscript.com/seals/11082897.png" alt="LegitScript approved" className="w-28 xl:w-32" border="0" /></a>
					<img src="https://storage.googleapis.com/instant-med-public/ISO_IEC_Certified.png" alt="ISO/IEC certified" border="0" className="w-28 xl:w-32 shrink-0 px-1"/>
				</div>

				<div className="flex flex-wrap sm:flex-nowrap md:justify-between gap-0 sm:gap-10 lg:gap-24 order-2 md:order-last">
					<div className="w-1/2 sm:w-fit order-3 sm:order-1 mt-8 sm:mt-0">
						<h4>Company</h4>
						<ul className="space-y-4 m-0 text-white text-sm pl-0">
						{company.map(({ name, link }, i) => {
							switch (name) {
							case 'Contact Us':
								return (
								<li key={name + i} onClick={()=>app.trigger(app.events.SHOW_POPUP, {
									"tp": "pu",
									"pt": "contact",
									"txt": "Contact Us",
									"u": "contact-us",
									"pn": "contact-us",
									"target":"iscr"
								})}>
								<Link to={link}>{name}</Link>
								</li>);
							default:
								return <li key={name + i}>
										{link.startsWith('https://') ? <a href={link} target="_blank">{name}</a> : 
										<Link to={link}>{name}</Link>}
									</li>
						}})}
						</ul>
					</div>

					<div className="w-1/2 sm:w-fit order-1 sm:order-2">
						<h4>For Patients</h4>
						<ul className="space-y-4 m-0 text-white text-sm pl-0">
							{patients.map(({ name, link }, i) => {
								return  <li key={name + i}>
											{link.startsWith('https://') ? <a href={link} target="_blank">{name}</a> : 
											<Link to={link}>{name}</Link>}
										</li>
								})
							}
						</ul>
					</div>

					<div className="w-1/2 sm:w-fit order-2 sm:order-3">
						<h4>For Staff</h4>
						<ul className="space-y-4 m-0 text-white text-sm pl-0">
							{staff.map(({ name, link }, i) => {
								return <li key={name + i}>
									{link.startsWith('https://') ? <a href={link} target="_blank">{name}</a> :
										<Link to={link}>{name}</Link>}
								</li>
							})}
						</ul>
					</div>
				</div>
			  </div>

			  <div className="pt-4 flex flex-wrap xl:flex-nowrap text-left justify-between w-full">
				<div className="flex flex-wrap lg:flex-nowrap items-center justify-start text-white gap-6 mb-8 xl:mb-0">
					<p className="mb-4 text-sm" style={{marginBottom:0}}>
						© {new Date().getFullYear()} InstantScripts Level 8 / 637 Flinders St., Docklands VIC 3008
					</p>
					<div className="flex gap-6">
						<p className="font-bold text-sm cursor-pointer" style={{ marginBottom: 0 }} onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Privacy', pn: 'privacy-policy' })}>Privacy</p>
						<p className="font-bold text-sm cursor-pointer" style={{ marginBottom: 0 }} onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Customer Terms and Conditions', pn: 'customer-terms-and-conditions' })}>Customer T&C</p>
				    	<p className="font-bold text-sm cursor-pointer" style={{ marginBottom: 0 }} onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Platform Terms and Conditions', pn: 'platform-terms-and-conditions' })}>Platform T&C</p>
					</div>
				</div>
				<div className="flex items-center justify-start lg:justify-end w-full md:w-fit gap-4 mb-8 xl:mb-0">
					<a href="https://www.facebook.com/InstantScripts/" target="_blank"><img className="h-6 w-6" src="https://storage.googleapis.com/instant-med-public/Emails/facebook-icon-white.png" alt="Facebook" /></a>
					<a href="https://www.instagram.com/instantscripts/" target="_blank"><img className="h-6 w-6" src="https://storage.googleapis.com/instant-med-public/Emails/insta-icon-white.png" alt="Instagram"/></a>
					<a href="https://apps.apple.com/au/app/instantscripts/id1456598384" target="_blank"><img className="h-8 w-auto md:h-[2.5rem] mx-auto" src="https://storage.googleapis.com/instant-med-public/app-store.png" alt="Download the InstantScripts App on Apple App Store"/></a>
					<a href="https://play.google.com/store/apps/details?id=au.com.instant" target="_blank"><img className="h-8 w-auto md:h-[2.5rem] mx-auto" src="https://storage.googleapis.com/instant-med-public/google-play.png" alt="Download the InstantScripts App on Google Play"/></a>
				</div>
			  </div>

				<p className="text-sm text-[#7B7B7B]">
					{app.dvc.org && <b>{app.dvc.org.name}<br /></b> || null}
					{app.runtime.org?.oid != 'app' && <i>{app.runtime.org?.name}<br /></i> || null}
				</p>

				<div className="noflex my-4 text-sm text-[#7B7B7B]">
					<Icon name="refresh" onClick={_ => window.location.reload(true)} style={{ cursor: 'pointer', color: '#7B7B7B' }}/>
					Build: {__GIT_HASH__} - {__BUILD__}
					{app.dev_env && ' - dev ' || app.test_env && ' - test ' || ' '}
					
					 <DebugExperiments />
					 <SiteVariationSelector />
					 {!app.dev_env  && <span className='hidden'>
						<Experiment id='ex3'>
					 	<Experiment.Variant variant='t1' defaultVariant>31</Experiment.Variant>
					 	<Experiment.Variant variant='t2'>32</Experiment.Variant>
                    </Experiment>
					 <Experiment id='ex4'>
					 	<Experiment.Variant variant='t2_1' defaultVariant>41</Experiment.Variant>
					 	<Experiment.Variant variant='t2_2'>42</Experiment.Variant>
					 	<Experiment.Variant variant='t2_3'>43</Experiment.Variant>
                    </Experiment>
					 </span>}
					
				</div>
			</Container>
		}

		</div>
	

}
