import React from 'react'
import currency from "currency.js";
import ProductCardButtons from "./ProductCardButtons";
import {Accordion, Icon, Modal} from "semantic-ui-react";
import {pick} from "../../../helpers/json_functions";
import {useDisclosure} from "../../../xAppLib/Hooks/useDisclosure";

const ProductCard = ({product, productInCart, onProductSelect, onProductQuantityChange, onProductRemove, allowMultiple= false, tapToExpand, disableActions}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {name, retail_price, discounted_price, imageAlt, image} = product

    const formattedRetailPrice = currency(retail_price).format()
    const formattedDiscountedPrice = currency(discounted_price).format()

    const actionArgs = { product, productInCart, onProductSelect, onProductQuantityChange, onProductRemove, allowMultiple, disableActions, isOpen, onClose }

    return (
        <>
            <div
                key={product.id}
                className={'relative min-h-full flex flex-col justify-between overflow-hidden rounded-lg border bg-white hover:cursor-pointer'}
                onClick={_ => tapToExpand && onOpen()}>
                {discounted_price && (
                    <span
                        className={'z-50 absolute top-2.5 right-2.5 text-white bg-red-600 rounded-xl px-2 py-1 text-xs'}>SALE</span>
                )}
                <div className={'px-4 py-5 sm:p-6 min-h-full flex flex-col justify-between'}>
                    <div className="flex flex-col mb-2 gap-1">
                        <div className="relative overflow-hidden rounded-lg mb-2">
                            <img
                                alt={imageAlt}
                                src={image}
                                className="object-contain max-h-20 lg:max-h-auto w-full object-center select-none"
                            />
                        </div>
                        <span className="text-sm text-gray-600 line-clamp-3">{name}</span>
                    </div>
                    <div className={'flex flex-col gap-2'}>
                        {discounted_price
                            ? <div className={'flex gap-2'}>
                                <span className="text-sm text-gray-600 line-through">{formattedRetailPrice}</span>
                                <span className="text-sm text-red-600">{formattedDiscountedPrice}</span>
                            </div>
                            : <span className="text-sm text-gray-600">{formattedRetailPrice}</span>
                        }
                        {!disableActions && <ProductCardButtons {...actionArgs}/>}
                    </div>
                </div>
            </div>
            <ProductInformation {...actionArgs} />
        </>

    )
}

const ProductInformation = (props) => {
    const {product, onProductSelect, productInCart, allowMultiple, onClose, isOpen} = props

    const handleProductSelect = () => {
        onProductSelect(product)
        onClose()
    }

    // const actionArgs = {product, productInCart, onProductSelect: handleProductSelect, allowMultiple: allowMultiple}

    return (
        <Modal open={isOpen} onClose={onClose} closeIcon={true}>
            <div className={'!p-6 grid grid-cols-2 gap-4'}>
               <div className={'col-span-2 h-full sm:col-span-1 flex items-center justify-center'}>
                    <img
                        alt={product.name}
                        src={product.image}
                        className="object-contain w-full"
                    />
                </div>
                <div className={'flex flex-col gap-2 my-auto col-span-2 sm:col-span-1'}>
                    <h4>{product.name}</h4>
                    <span className={'text-gray-600'}>{product.product_description}</span>
                    <p><strong>{currency(product.price).format()}</strong></p>
                    <div className={'md:max-w-[200px] mb-2'}>
                        <ProductCardButtons {...props}/>
                    </div>
                    <InformationAccordian {...product}/>
                </div>
            </div>
        </Modal>

    )
}

const InformationAccordian = (product) => {
    const [openPanes, setOpenPanes] = React.useState([])
    const keysToLabels = {
        mandatory_statement: 'Mandatory Statement',
        warnings: 'Warnings',
        storage_instructions: 'Storage Instructions'
    }

    const handleTogglePane = (key) => {
        if (openPanes.includes(key)) {
            setOpenPanes(openPanes.filter(pane => pane !== key))
        } else {
            setOpenPanes([...openPanes, key])
        }
    }

    const information = pick(product, ['mandatory_statement', 'warnings', 'storage_instructions'])
    return <Accordion>
        {Object.keys(information).map(key => {
            const informationSplit = information[key].split('\n')
            return (
                <div key={key} className={'text-gray-600'}>
                    <Accordion.Title active={openPanes.includes(key)} onClick={_ => handleTogglePane(key)}>
                        <Icon name='dropdown'/>
                        {keysToLabels[key]}
                    </Accordion.Title>
                    <Accordion.Content active={openPanes.includes(key)}>
                        <ul className={'list-disc px-4 text-sm'}>
                            {informationSplit.map((line, index) => <li className={'my-2'} key={index}>{line}</li>)}
                        </ul>
                    </Accordion.Content>
                </div>
            )
        })}
    </Accordion>
}

export default ProductCard