import React from "react";
import { Loader } from "semantic-ui-react";
import PrescriptionCard from "./PrescriptionCard";

export default function ActivePrescriptions({ data, loading, showProfileNames, limit, columnsLg }) {

    if (loading) return <Loader active inline className="after:!border-t-gray-500" />;

    const limitedData = typeof limit === 'number' ? data?.slice(0, limit) : data;

    return (
        <>
            {limitedData?.length > 0 && (
                <>
                    <h4>Latest Prescriptions:</h4>
                    <section className={`!gap-4 mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${columnsLg}`}>
                        {limitedData?.map((item) => (
                            <PrescriptionCard key={item.sid} item={item} showProfileNames={showProfileNames} />
                        ))}
                    </section>
                </>
            )}
        </>
    )
}