import React from 'react';
import { Link } from 'react-router-dom';
import { DebugExperiments } from "../../../xAppLib/UIelems/DebugExperiments";
import { SiteVariationSelector } from "../../../xAppLib/UIelems/SiteVariationSelector";
import DeviceOrgSelector from '../../icosm/DeviceOrgSelector';
import { Icon } from 'semantic-ui-react';
import { useUser } from '../../../xAppLib/Hooks';
import { cls } from '../../NUI/utils';
import { AppleAppStoreButton, GooglePlayStoreButton } from "./AppStoreButtons";
import { Divider } from './Divider';

const apps = <div className="flex justify-center gap-3">
                <AppleAppStoreButton/>
                <GooglePlayStoreButton/>
            </div>
    
export function FooterDownload() {
    const { user_in } = useUser();
    return  <div className={cls("text-center bg-is2-blue text-white")}>
                <div className="flex flex-col px-6 py-24 lg:py-[120px]">
                    <h2 className="text-xl md:text-[44px] leading-[40px] font-medium mb-0">Download the app</h2>
                    <p className="my-9 text-base leading-[20px]">For a better experience and additional features we recommend downloading our app.</p>
                    <div className="flex justify-center gap-3">
                        {apps}
                    </div>
                </div>
            </div>
}

export default function FooterMain({bg: _bg}) {

    const menu = [
        {
            name: "Language (English)",
            link: "",
        },
        {
            name: "Accessibility options",
            link: "",
        },
    ]

	return  <div className={`relative bg-is2-blue-600 text-white text-center pt-6 pb-3 px-6 z-10`}>
                <div className="flex flex-col items-center space-y-9">
                    <div className="flex gap-9 items-between">
                        {/*menu.map(({ name, link }, i) => {
                            return <Link key={i} className="text-xs text-white hover:text-white hover:underline underline-offset-2" to={link}>{name}</Link>
                        })*/}
                    </div>

                    <div>
                        <img src="https://storage.googleapis.com/instant-med-public/v2/partners/Racgp.svg" alt="InstantScripts" className="w-[132px] invert-[1] brightness-0 mb-2 mx-auto"/>
                        <a className="!text-white underline hover:no-underline underline-offset-2" href="https://careers.smartrecruiters.com/InstantScripts">Doctor careers</a>
                    </div>

                    <Divider clsn="lg:max-w-[400px]" color="darkblue" />
                    <div className="md:hidden">{apps}</div>

                    <img src="https://storage.googleapis.com/instant-med-public/v2/Instantscripts_logo_white.svg" alt="InstantScripts" className="logo mb-6 w-[132px]"/>
                    
                    <div>
                        <img src="https://storage.googleapis.com/instant-med-public/v2/Wesfarmers_logo_white.svg" alt="Wesfarmers" className="logo mb-2 h-[25px] mx-auto"/>
                        <p className="text-xs mb-2">A Wesfarmers company</p>
                    </div>
                        
                    <div className="flex justify-between w-fit gap-12">	
                        <a href="https://www.legitscript.com/websites/?checker_keywords=instantscripts.com.au" target="_blank" className="h-fit shrink-0"><img src="https://static.legitscript.com/seals/11082897.png" alt="LegitScript approved" className="h-[90px]" border="0" /></a>
                        <img src="https://storage.googleapis.com/instant-med-public/ISO_IEC_Certified.png" alt="ISO/IEC certified" border="0" className="h-[90px] shrink-0 px-1"/>
                    </div>

                    <p className="uppercase text-xs">© InstantScripts  ABN 28 008 984 049</p>
                </div>

                <div className="text-sm">
                    <DeviceOrgSelector />
                    {app.runtime.org?.oid!='app'&&<i>{app.runtime.org?.name}<br/></i> || null}
                    Build: {__GIT_HASH__} - {__BUILD__}
                    {app.dev_env &&  ' - dev' || app.test_env &&  ' - test' || ''}
                    <Icon name="refresh" onClick={_=>window.location.reload(true)} className="cursor-pointer !ml-2" />
                    <DebugExperiments />
                    <SiteVariationSelector />
                </div>
            </div>
}
