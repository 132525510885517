import * as Sentry from '@sentry/browser';
import user_model from "./user_model";
import orgs_model from "./orgs_model";
import moment from "moment";
import firebase_auth from "xAppLib/providers/firebase_auth";
import gtm from "xAppLib/providers/gtm";
import logger from 'xAppLib/libs/logger';
import { parseState } from 'helpers/address';
import doc_model from "./doc_model";
import docs_model from "./docs_model";
import {from_melb_ui_tm} from "../helpers/datetime";

const DEBUG = false;
const DO_PROF_REFRESH  = false
const VERIF_ENABLED = true

class AppUser {

	user_in = false;
	uid = null;
	user_obj = null;
	user_det = null;
	claims = {};
	profs = [];
	prof = null;
	prefs = {};
	org = {};

	constructor() {}

	async init(user, idTokenResult) {

		if (!user) {
			return this.init_default()
		}
		this.refresh(user,idTokenResult);

		const [org, prof_data, doc_det] = await Promise.all([
			this.load_org(idTokenResult.claims?.oid),
			this.load_profile(user),
			idTokenResult.claims?.doc && idTokenResult.claims?.doc_id && doc_model.get_record(idTokenResult.claims.doc_id)
		]);
		this.org = org;

		if (doc_det)
			this.doc_det = {...doc_det, active: await docs_model.get_record(`${doc_det.l}/i/${doc_det.m}/active`)};

		if(prof_data?.res == 'err'){
			logger.report_error('ERROR retrieving profile AppUser.init()', 'error', {user,prof_data});
		}
		else if(Object.keys(prof_data||{}).length == 0){
			logger.report_error('ERROR empty profile object AppUser.init()', 'error', {user,prof_data});
		}
		
		this.set_profile(prof_data)

		this.user_in = true;
		/**
		 * Checking if user has just signed up using social login
		 */
		if(prof_data.new_prof){
			// 
			// 
			// Can be used to perform new signup actions
			app.signup = true;
			// 
			// gtm signup via social only event
			gtm.auth('signup_soc',{type:'pts_signup'});
            
		}

		Sentry.configureScope( scope => scope.setUser({ email: user.email, username: user.displayName, claims: idTokenResult.claims, oi: this.org?.oid, onm: this.org?.name }) );

		window.Intercom(
					'boot', 
					{  
						app_id: 'ksq5hplh',  
						email: this.user_det.email,
						created_at: moment(prof_data.cre_tm).valueOf(),
						name: this.user_det.displayName,
						user_id: this.user_det.uid,
						user_hash: prof_data.inuiha,
						hide_default_launcher: !app.site_status?.use_intercom || app.settings.is_cbls,
					}
				);

		this.set_verify_start()
		this.set_mfa_start()
		this.pol_user(true)

		DO_PROF_REFRESH && user_model.refresh_prof()

	}

	async refresh(user,idTokenResult) {
		this.uid = user.uid;
		this.token = idTokenResult.token;
		this.user_obj = user;
		this.claims = idTokenResult.claims || {};
		this.user_det = {
			displayName: user.displayName,
			email: user.email || user.providerData?.[0]?.email,
			provider: user.providerData?.[0]?.providerId || "password",
			emailVerified: user.emailVerified,
			isAnonymous: user.isAnonymous,
			phoneNumber: user.phoneNumber || user.providerData?.[0]?.phoneNumber,
			photoURL: user.providerData?.[0]?.photoURL || user.photoURL,
			uid: user.uid,
			providerData: user.providerData,
		};
	}

	async init_default() {
		Sentry.configureScope(scope => scope.setUser(null));
		const org_data = await this.load_org("app");
		this.org = org_data;
		

	}

	cleanup() {
		this.pol_user(false)

		app.trigger(app.events.USER_CONTEXT, null)
	}

	set_profile(prof_data) {
		DEBUG && console.log("get_profs",{prof_data});
		const [prof,profs] = AppUser.parse_profile(prof_data)
		this.prof = prof
		this.profs = profs;
		this.prefs = prof.extras?.prefs || {}
		
	}

	static parse_profile(prof_data) {
		let { sub = [], ...prof } = prof_data;
		const profs = [prof,...(sub||[])];
		for (const p of profs) {
			if (p.dob)
				p.dob = moment(p.dob).format("YYYY-MM-DD")
			if (p.full_address?.state) {
				
				if ((p.full_address.state = parseState(p.full_address.state) ) == '') {
					p.full_address.formatted = ''
					p.address = ''
				}
					
			}

			const hist = p.hist || prof.hist || [];
			const used = Object.fromEntries(hist.filter(hs => hs.meta_ref_sid).map(hs => [hs.meta_ref_sid, true]).filter(Boolean));
			const has_subscription = Object.fromEntries((p.pts_plans || prof.pts_plans || []).map(p => [p.sid, p.status === 'active']));
			const ALLOW_MULTIPLE_FUs = true;
			p.hist = hist
				.map(hs => ({hs, created: from_melb_ui_tm(hs.add_tm)}))
				.sort((x, y) => y.created - x.created)
				.map(({hs}) => {
					const fu_days = Number(hs.med_db_data?.FU_days) || has_subscription[hs.sid] && 100;
					if (fu_days && (ALLOW_MULTIPLE_FUs || !used[hs.sid]) && hs.status === "done_doccall") {
						const FU_until = from_melb_ui_tm(hs.add_tm).add(fu_days, 'days').toDate().getTime();
						return {...hs, med_db_data: {...hs.med_db_data, FU_until}};
					}
					return hs;
				})
		}
		return [prof,profs]
	}

	idtoken(forceRefresh = false) {
		return this.user_obj?.getIdToken(forceRefresh)
	}



	get name() {
		return (
			(this.prof?.first_name && `${this.prof.first_name} ${this.prof.last_name}`) || this.user_det?.displayName || ""
		);
	}
	get shortname() {
		return this.prof?.first_name || this.user_det.displayName || "";
	}
	get usePassword() {
		return this.user_det?.providerData[0]?.providerId == "password";
	}
	get email() {
		return this.user_det?.email || this.prof?.email;
	}

	get is_email_verified() { 
		return !VERIF_ENABLED || !(this.user_in && !this.user_det?.emailVerified ) || ['-LQ9VoSOJ4E1lWq_VzZg', 'D0UGCxw', '-LT4xlvwxxTWUpjPOjCF'].includes(this.org?.path?.[3]?.oid) 
	}

	get is_email_blocked() { return !this.is_email_verified && app.settings.verification_days <= 0 }

	get is_mfa_blocked() { return app.acl.need_mfa && app.settings.mfa_days <= 0 }
	
	get is_terms_and_conditions_blocked() { return this.user_in && !this.prof?.meta?.consent }

	get role() { return this.claims && user_model.role_name(this.claims, true) }

	get enrolledFactors() {
		return firebase_auth.enrolledFactors()
	}

	async load_org(oid) {
		if (oid) {
			if (oid == this.org?.oid)
					return this.org
			else {
				let org_data = await orgs_model.get_record(null, null, {
					act: "getusrorg",
					oid: oid,
				});
				if (!org_data || (org_data && org_data.res != "ok")) {
					org_data = await orgs_model.get_record(null, null, {
						act: "getusrorg",
						oid: oid,
					});
				}
				return (org_data?.res == "ok" && org_data)  || {};
			}
		}
		else return {};
	}

	async load_profile(user) {
		return (await user_model.load_prof(user.uid)) || {};
	}

	set_verify_start() {
			// Saving date we started showing verify popup
			
		if (this.prof?.extras?.verif || this.user_det?.emailVerified)
			return
	
		const start = moment().format("YYYY-MM-DD")
		const extras = { ...(this.prof.extras||{}),verif:start}
		user_model.save_prof({extras})
		this.prof.extras=extras
								
	}

	
	set_mfa_start() {
			
		if (this.prof?.extras?.mfa_st || !app.acl.need_mfa || app.acl.has_mfa)
			return
	
		const start = moment().format("YYYY-MM-DD")
		const extras = { ...(this.prof.extras||{}),mfa_st:start}
		user_model.save_prof({extras})
		this.prof.extras=extras
								
	}




	pol_user(start) {
		
		if (!start) {
			if (this.userPol) {
				clearInterval(this.userPol)
				this.userPol = null
			}
			return
		}

		if (this.userPol) {
			console.log('Already polling');
			return
		}

		if (this.is_email_verified)
			return

		this.userPol = setInterval(async _=>{
			// console.log('polling');
			const currentUser = await firebase_auth.reload()
			if (!currentUser || currentUser.uid != this.uid)
				return this.pol_user(false)
			if (currentUser.emailVerified) {
				console.log('verified, thank you');
				gtm.auth('verified')
				this.pol_user(false)
			}
		},1500)
	}

	reload_profile(p = {}) {
		return user_model.load_prof(undefined, p).then(profile => {
			this.set_profile(profile)
			app.trigger(app.events.USER_UPD);
		})
	}
 
	
}

export default AppUser;
