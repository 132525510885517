import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import React, { createContext, useContext } from "react";
import API_service from "xAppLib/providers/API_service";

export const ContentfulContext = createContext();

export const useContentfulProvider = () => useContext(ContentfulContext);

export const ContentfulProvider = ({ children, space, preview }) => {
  const getEntry = async (params) =>
    await API_service.load_data("cms/getEntries", params);

  const content = (
    <ContentfulContext.Provider value={{ getEntry }}>
      {children}
    </ContentfulContext.Provider>
  );

  if (!preview) return content;

  return (
    <ContentfulLivePreviewProvider
      locale="en-AU"
      enableInspectorMode
      debugMode
      enableLiveUpdates
      liveUpdates
      space={space}
      targetOrigin="https://app.contentful.com"
    >
      {content}
    </ContentfulLivePreviewProvider>
  );
};
