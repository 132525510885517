import React, { useState, useEffect } from 'react';
import {
	Menu,
	Image,
} from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom';

import { Deploy } from 'cordova-plugin-ionic';
import { slide as MenuContainer } from 'react-burger-menu'

import AdminMenu from './AdminMenu'
import MainMenuItems from './MainMenuItems'
import { useUser } from 'xAppLib/Hooks';
import MegaMenu from '../MenuNav/MegaMenu';
import IcosmSideMenu from '../../views/icosm/components/IcosmSideMenu';
import IcosmMenu from '../MenuNav/IcosmMenu';
import ServiceAndConditionsMenu from '../../views/UIelems/v2/serviceAndConditionsMenu/ServiceAndConditionsMenu';
import CloseSVGIcon from '../../styles/public/icons/CloseSVGIcon';
import BottomMenu from './BottomMenu';
import LoginPopup from '../Users/LoginPopup';

const SideMenu = ({ showMenu, onMenuChange }) => {
	const user = useUser()
	const [devChannel, setDevChannel] = useState(false)
	const [showSideMenu, setSideMenu] = useState(true);
	const showCosmMenu = app.settings?.icosm && app.user.user_in && !app.acl.is_usr;
	const SideMenu = showCosmMenu ? 'div' : Menu; // Escape global menu styling for cosm
	const location = useLocation()
	const curr_page = location.pathname

	useEffect(() => {
		async function get_channel() {
			try {
				const info = await Deploy.getCurrentVersion()
				setDevChannel({ devChannel: info?.channel == 'dev' })
			} catch (e) {
				console.log(e)
			}
		}
		if (app.settings.is_ionic) {
			get_channel()
		}
	}, [])

	return (
		<MenuContainer pageWrapId={"page-wrap"}
			outerContainerId={"app"}
			isOpen={showMenu}
			customBurgerIcon={false}
			onStateChange={({ isOpen }) => onMenuChange?.(isOpen)}
			width={400}
			className={`${app.settings.iscr2 ? 'bm-menu-wrap-iscr2' : ''} ${user.user_in ? 'user-in' : ''}`}
			right
		>
			{app.settings.iscr2 &&
				<nav className="!flex lg:!hidden !justify-between !items-center !py-2 !px-4 !text-white !fixed !w-full !top-0 !bg-is2-blue !mb-0">
					<Image
						bordered={false}
						src={app.runtime.sidemenuLogo || "/images/logos/instant-scripts-logo2-wide-white.svg"}
						className="side-logo !m-0 !p-0 !max-h-6 !h-6"
					/>
					{/*(user.user_in == false || app.runtime?.login_type == 'popup' && app.state.in_sgup_st2)
						&& !(app.dvc.org.type == 'pharm'
							&& (document.location.hostname == "pharmapp.instantscripts.com.au" || document.location.hostname == "safe-scripts.firebaseapp.com")
							&& (app.settings.is_tablet || app.settings.is_mob)
						)
						&& (app.runtime?.login_type == 'popup' ?
							<LoginPopup className={"ui button !text-white !bg-[#005BDA] !text-sm !rounded-full !px-3 !py-1 !ml-auto !m-0 !mr-3"} />
							:
							<Menu.Item
								content={'Log in'}
								active={curr_page === '/login'}
								as={Link}
								to={'/login'}
								key={'header_menu_mob_login'} />
						)
					*/}
					<button className="" onClick={_ => { onMenuChange?.(false) }}>
						<CloseSVGIcon width={26} fill="#005BDA" />
					</button>
			</nav>
			}
			

			{!app.settings.iscr2 &&
				<SideMenu
					inverted vertical fluid className="side-menu"
					data-testid="menu-collapsible"
					onClick={e => { onMenuChange(false); console.log(e) }}
				>
					{app.settings.iscr && <MegaMenu inSideMenu setMainSideMenu={(v) => { setSideMenu(v) }} />}
					{app.settings.icosm && !user.user_in && <IcosmMenu inSideMenu setMainSideMenu={(v) => { setSideMenu(v) }} />}
					{showSideMenu && !showCosmMenu && <MainMenuItems inSideMenu />}
					{showSideMenu && showCosmMenu && <IcosmSideMenu collapse />}
				</SideMenu>
			}

			{app.settings.iscr2 &&
				<SideMenu
					inverted vertical fluid className="side-menu"
					data-testid="menu-collapsible"
				>
					<ServiceAndConditionsMenu onMenuChange={onMenuChange} showMenu={showMenu} />
					<BottomMenu inSideMenu setMainSideMenu={(v) => { setSideMenu(v) }} onMenuChange={onMenuChange} />
				</SideMenu>
			}

			{(user.claims.admin && showSideMenu) && <AdminMenu inverted vertical inSideMenu />}
			{app.settings.is_ionic && <Menu
				inverted vertical fluid className='side-meta'
				onClick={_ => { onMenuChange?.(false) }}
			>
				<Menu.Item fitted='vertically'>© {new Date().getFullYear()} InstantScripts</Menu.Item>
				<Menu.Item className='version'>Build: {__GIT_HASH__} - {__BUILD__} {devChannel && "dev"}
					{app.runtime.org && <i><br />{app.runtime.org.name}</i> || null}
					{app.dvc.org && <><br />{app.dvc.org.name}</> || null}</Menu.Item>
			</Menu>}

		</MenuContainer>
	)
}

export default SideMenu;
