import React from "react";
import { Button, Icon, Loader, Popup } from "semantic-ui-react";
import { cls } from "../../NUI/utils";

export function QuestionsField(props) {
	const {onChange, value, label, options, name, note, details, disabled} = props;
	const error = Boolean(props.error);

	const loading = props.loading === true
		? <Loader inline active/>
		: props.loading;

	return (
		<Question>
			<Question.Text>
				{label}
				{note && <Popup
					trigger={<Icon name="question circle outline"/>}
					content={note}
				/>}
			</Question.Text>

			{details && <Question.Details>{details}</Question.Details>}

			{loading || <Question.Answers>
				{options.map(opt => (
					<Question.Answer key={opt.value}
									 value={opt.value}
									 onClick={_ => onChange({target: {name, value: opt.value}})}
									 selected={value === opt.value}
									 right={opt.right}
									 error={error}
									 disabled={disabled}
					>
						{opt.text}
					</Question.Answer>
				))}
			</Question.Answers>}
		</Question>
	);
}

export function Questions({children}) {
	return <div className="space-y-4">{children}</div>;
}

export function Question({children}) {
	return <div className="space-y-4">{children}</div>;
}

export function QuestionText({children}) {
	return <h4 className="font-thin mb-4"><strong data-testid='question-text'>{children}</strong></h4>;
}

export function QuestionDetails({children}) {
	return <p className="text-tertiary">{children}</p>;
}

export function Answers({children, className}) {
	return (
		<div className={cls("grid grid-cols-1 md:grid-cols-2 gap-4", className)}>
			{children}
		</div>
	);
}

export function Answer({children, value, onClick, selected, right, error, disabled, loading}) {
	return (
		<Button type="button"
				onClick={_ => onClick(value)}
				primary={selected}
				basic={!selected}
				color={error ? 'red' : undefined}
				disabled={disabled}
				loading={loading}
		>
			<span className={cls("flex flex-row", right ? "justify-between" : "justify-center")}>
				<span>{children}</span>
				{right && <span>{right}</span>}
			</span>
		</Button>
	);
}

Questions.Question = Question;
Question.Text = QuestionText;
Question.Details = QuestionDetails;
Question.Answers = Answers;
Question.Answer = Answer;